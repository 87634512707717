<template>
  <div>
    <div
      class="up-part content d-flex d-flex-row justify-content-center align-items-center"
    >
      <p :class="darkModeEnabled ? ' text-light' : ' text-dark'">
        <img
          src="@/assets/MGAssets/Help Question.png"
          alt="Help Icon"
          class="icon-help"
        />
        هنساعدك ازاى
      </p>
    </div>
    <div class="container py-5">
      <div class="row g-4">
        <div
          v-for="(instruction, index) in instructions"
          :key="index"
          class="col-12 col-sm-6 col-md-4 col-lg-3"
        >
          <div
            class="card text-center shadow"
            :class="
              darkModeEnabled ? 'bg-custom text-white' : 'bg-white text-dark'
            "
            :style="getCardAnimation(index)"
          >
            <div class="card-body">
              <div class="icon-container mb-3">
                <!-- Dynamically load GIF icons -->
                <img :src="instruction.gif" alt="Icon" class="gif-icon" />
              </div>
              <p class="card-text">{{ instruction.text }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomePage",
  data() {
    return {
      instructions: [
        {
          gif: require("@/assets/MGAssets/GIF/target.gif"),
          text: "شرح يخليك تعلي على أي حد",
        },
        {
          gif: require("@/assets/MGAssets/GIF/notebook.gif"),
          text: "امتحانات وواجبات تفاعلية كتير",
        },
        {
          gif: require("@/assets/MGAssets/GIF/6454106.gif"),
          text: "متابعة مستمرة و تقرير دوري للمستر",
        },
        {
          gif: require("@/assets/MGAssets/GIF/checklist.gif"),
          text: "منتدى علمي يرد على كل أسئلتك على مدار الساعة",
        },
      ],
    };
  },
  computed: {
    darkModeEnabled() {
      return this.$store.getters.isDarkModeEnabled;
    },
  },
  methods: {
    getCardAnimation(index) {
      const animations = [
        "fade-up",
        "fade-down",
        "fade-left",
        "fade-right",
        "zoom-in",
        "zoom-out",
      ];
      const randomAnimation = animations[index % animations.length];
      return `animation: ${randomAnimation} 1s ease-in-out;`;
    },
  },
};
</script>

<style scoped>
.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 60px;
  margin: 0 auto;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.1);
}
.bg-custom {
  background-color: #262626 !important; /* Replace with your custom color */
}
.gif-icon {
  height: 100%;
  width: 100%;
  object-fit: contain;
  border-radius: 50%;
}

.card {
  max-height: 25vh;
  min-height: 25vh;
  height: auto;
  border-radius: 1rem;
  transition: transform 0.3s ease-in-out;
}

.card:hover {
  transform: scale(1.05);
}

.card-body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
}

/* Add animations */
@keyframes fade-up {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fade-down {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fade-left {
  from {
    transform: translateX(-20px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fade-right {
  from {
    transform: translateX(20px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes zoom-in {
  from {
    transform: scale(0.9);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes zoom-out {
  from {
    transform: scale(1.1);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
.up-part {
  margin-top: 50px;
}
</style>

<!-- <template>
  <div
    class="content d-flex d-flex-row justify-content-center align-items-center"
    :class="{ 'dark-mode': darkModeEnabled }"
  >
    <div class="up-part">
      <p>
        <img
          src="@/assets/MGAssets/Help Question.png"
          alt="Help Icon"
          class="icon-help"
        />
        هنساعدك ازاى
      </p>
    </div>
    <div class="layout">
      <div class="down-part">
        <div class="cell">
          <div class="icon">
            <img src="@/assets/MGAssets/r1.png" alt="Icon 1" />
          </div>
          <p>شرح يخليك تعلي على اي حد</p>
        </div>
        <div class="cell">
          <div class="icon">
            <img src="@/assets/MGAssets/r2.png" alt="Icon 2" />
          </div>
          <p>امتحانات وواجبات تفاعلية كتير</p>
        </div>
        <div class="cell">
          <div class="icon">
            <img src="@/assets/MGAssets/r3.png" alt="Icon 3" />
          </div>
          <p>متابعة مستمرة و تقرير دوري للمستر</p>
        </div>
        <div class="cell">
          <div class="icon">
            <img src="@/assets/MGAssets/r4.png" alt="Icon 4" />
          </div>
          <p>منتدى علمي يرد على كل أسئلتك على مدار الساعة</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HelpSection",
  computed: {
    darkModeEnabled() {
      return this.$store.getters.isDarkModeEnabled;
    },
  },
};
</script>

<style scoped>
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 44px;

  /* position: absolute; */
  width: 100%;
  height: 434px;
  /* left: 221px; */
  /* top: c800px; */
}

.layout {
  width: 60%;
}

.up-part p {
  font-family: "Cairo", sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.63;
  text-align: center;
  color: #000;
  display: flex;
  align-items: center;
  gap: 10px;
}

.down-part {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.cell {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  gap: 19px;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.icon {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 10px;
}

.icon-help {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 10px;
}

.cell p {
  font-weight: 600;
  font-size: 16px;
  line-height: 1.63;
  color: #1e1e1e;
  margin: 0;
  flex: 1;
  text-align: right;
}

@media (max-width: 450px) {
  .layout {
    width: 100%;
  }
}

.dark-mode.content p {
  color: white;
}
</style> -->
