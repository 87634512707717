import { createStore } from "vuex";

export default createStore({
  state: {
    isAuthenticated: false,
    userToken: null,
    darkMode: false,
    paymentID: null,
    student_name: null
  },
  getters: {
    isDarkModeEnabled: (state) => state.darkMode,
    isAuthenticatedStatus: (state) => state.isAuthenticated,

  },
  mutations: {
    LOGIN_USER(state, token) {
      state.isAuthenticated = true;
      state.userToken = token;
    },
    LOGOUT_USER(state) {
      state.isAuthenticated = false;
      state.userToken = null;
    },
    SET_DARK_MODE(state, value) {
      state.darkMode = value;
    },
    SET_PAYMENT_ID(state, id) {
      state.paymentID = id;
    },
    SET_STUDENT_NAME(state, name) {
      state.student_name = name;
    },
  },
  actions: {},
  modules: {},
});
