<template>
  <div class="container">
    <div class="export-container">
      <button class="export-button" id="toEXCEL" @click="exportToExcel">
        تحميل ملف Exel
      </button>
    </div>
    <div class="" id="customers_table">
      <!-- table__body -->
      <div class="table-responsive rounded">
        <table class="table table-striped">
          <thead>
            <tr>
              <th>التسلسل</th>
              <th>أسم الدرس</th>
              <th>أسم الكورس</th>
              <th>مدة المشاهدة</th>
              <th>عدد مرات المشاهدة</th>
              <th>اول مره مشاهده</th>
              <th>اخر مره تم المشاهده</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="is_loading" class="loading">
              <td colspan="12">
                <LoadingComponent />
              </td>
            </tr>
            <template v-if="!is_loading && views_data.length > 0">
              <tr v-for="(view, index) in views_data" :key="view?.id"  class="table_row">
                <td>{{ index + 1 }}</td>
                <td>{{ view?.lesson__name }}</td>
                <td>{{ view?.course_name }}</td>
                <td class="">
                  <p class="m-0">ثواني : دقائق : ساعات</p>
                  {{ convertSeconds(view?.total_watch_time) }}
                </td>
                <td>{{ view?.counter }} من {{ view?.lesson_view }}</td>
                <td>{{ getHumanDate(view?.created) }}</td>
                <td>{{ getHumanDate(view?.updated) }}</td>
              </tr>
            </template>
            <tr v-if="!is_loading && views_data.length == 0">
              <td colspan="12" class="no-data">
                لا يوجد تفاصيل مشاهدات حتي الان
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="table-footer">
        <div class="ms-2">
          صفوف الصفحه
          <select name="limit" class="limit-select" v-model="limit">
            <option value="10">10</option>
            <option value="100">100</option>
            <option value="1000">1000</option>
            <option value="10000">10000</option>
          </select>
        </div>
        <div>
          <span>1-{{ limit }}</span>
          من
          <span>{{ view_count }}</span>
        </div>
      </div>
    </div>
    <PaginationBtns />
  </div>
</template>

<script>
import axios from "axios";
import exportToExcel from "../../../services/csv";
import config from "@/services/config";
import { watchEffect } from "vue";
import LoadingComponent from "@/components/Loading/LoadingComponent.vue";
import PaginationBtns from "@/components/PaginationBtns/PaginationBtns.vue";
export default {
  name: "ViewsDetailsComponent",
  components: {
    PaginationBtns,
    LoadingComponent,
  },
  data() {
    return {
      views_data: [],
      hav_next: false,
      hav_prev: false,
      is_loading: true,
      view_count: 0,
      limit: 10,
    };
  },
  watch: {
    limit() {
      this.$router.push({
        name: "details_views",
        query: { page: this.$route.query.page || 1, limit: this.limit },
      });
    },
  },
  methods: {
    exportToExcel() {
      exportToExcel();
    },
    get_views() {
      this.is_loading = true;
      this.views_data = [];

      let link = `${config.baseURL}student/lesson-views-list`;

      if (Object.keys(this.$route.query).length > 0) {
        let query_keys = Object.keys(this.$route.query);
        query_keys.forEach((key, index) => {
          if (index == 0) {
            link += `?${key}=${this.$route.query[key]}`;
          } else {
            link += `&${key}=${this.$route.query[key]}`;
          }
        });
      }

      axios
        .get(link, {
          headers: {
            auth: this.$store.state.userToken,
          },
        })
        .then((res) => {
          this.views_data = res.data.results;
          this.is_loading = false;
          this.hav_next = res.data.next ? true : false;
          this.hav_prev = res.data.previous ? true : false;
          this.view_count = res.data.count;
        })
        .catch((err) => {
          console.log(err.message);
        });
    },
    convertSeconds(seconds) {
      const hours = Math.floor(seconds / 3600); // 1 hour = 3600 seconds
      const minutes = Math.floor((seconds % 3600) / 60); // Remaining minutes
      const remainingSeconds = seconds % 60; // Remaining seconds

      return `${hours} : ${minutes} : ${remainingSeconds}`;
    },
    getHumanDate(d) {
      const date = new Date(d);
      const options = {
        weekday: "long",
        year: "numeric",
        month: "short",
        day: "numeric",
      };
      return date.toLocaleDateString("ar", options);
    },
  },
  created() {
    watchEffect(() => {
      this.get_views();
      this.limit = this.$route.query.limit || 10;
    });
  },
};
</script>

<style scoped>
.export-container {
  text-align: center;
  margin-bottom: 2rem;
}

.export-button {
  background-color: #fff;
  color: #000;
  box-shadow: 0px 0px 2px 0px #000000;
  padding: 1rem 2rem;
  border-radius: 30px;
  cursor: pointer;
  border: none;
  outline: none;
  font-size: 1rem;
  transition: all 0.3s;
}

.export-button:hover {
  background-color: #038c4c;
  color: #fff;
}

.table-responsive {
  max-height: 75vh;
  overflow-y: auto;
  background-color: #fff;
}
body.dark .table-responsive {
  background-color: #1d4955;
}
body.dark .no-data {
  color: #fff;
  background-color: #1d4955;
}
table thead tr th,
table tbody tr td {
  text-align: center;
  text-wrap: nowrap;
}

.table-footer {
  padding: 1rem 0.5rem;
  display: flex;
  justify-content: end;
  align-items: center;
}
body.dark .table-footer {
  color: #fff;
}

body.dark .loading {
  background-color: #1d4955;
}
.loading td {
  padding: 0;
}

.table-footer select.limit-select {
  all: unset;
  padding: 0.3rem;
  background-color: #eeeeee67;
  text-align: center;
  border-radius: 0.5rem;
}

/*
.table> :not(caption)>*>* {
  background-color: transparent;
}

.table {
  position: relative;
  width: 100%;
  height: 75vh;
  background-color: #fff;
  box-shadow: 0 0.4rem 0.8rem #0005;
  border-radius: 1.25rem;
  overflow: hidden;
  padding: 2rem;
  direction: ltr;
}

.table__body {
  background-color: #fff;
  width: 100%;
  height: 100%;
  border-radius: 1.25rem;
  padding: 0 2rem 2rem 2rem;
  overflow: auto;
  overflow: overlay;
}

.table__body::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

.table__body::-webkit-scrollbar-thumb {
  border-radius: 0.5rem;
  background-color: #c3c3c3;
  border: 1px solid #c3c3c3;
}

.table__body::-webkit-scrollbar-track {
  background-color: #eeeeee;
}

table {
  width: 100%;
  direction: rtl;
}

table,
th,
td {
  border-collapse: collapse;
  padding: 1rem 0.5rem;
  text-align: center;
  min-width: 190px;
  min-height: 31px;
}

thead th {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: #fff;
  color: #000;
  border: none;
  border-bottom: 0.1rem solid #00000020;
}

tbody tr {
  background-color: #fff;
  border: none;
  border-bottom: 0.1rem solid #00000010;
}

th {
  color: #000;
  font-weight: 500;
}

td {
  color: #00000070;
  font-weight: 300;
}
*/
/* Dark Mode */
body.dark th {
  color: #fff;
}

body.dark .export-button {
  background-color: #1d4955;
  color: #fff;
}

body.dark .export-button:hover {
  background-color: #038c4c;
}

body.dark .table__body::-webkit-scrollbar-thumb {
  background-color: #235562;
  border: 1px solid #13323a;
}

body.dark .table__body::-webkit-scrollbar-track {
  background-color: #13323a;
}

body.dark table tbody tr.table_row,
body.dark table tbody tr.table_row td,
body.dark .table__body,
body.dark .table {
  background-color: #1d4955 !important;
}

body.dark thead th {
  background-color: #1d4955;
  color: #fff;
  border: none;
  border-bottom: 0.1rem solid #ffffff20;
}

body.dark td {
  color: #ffffff70;
}

/*  Media queries */
@media screen and (max-width: 992px) {
  .export-button {
    padding: 0.75rem 1.5rem;
    font-size: 0.9rem;
  }

  .table {
    padding: 1rem;
  }

  .table__body {
    padding: 0 1rem 1rem 1rem;
  }

  th,
  td {
    font-size: 0.9rem;
  }
}
</style>
