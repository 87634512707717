<template>
  <div class="container">
    <div class="con">
      <div
        class="notifications"
        :style="{
          display: is_loading || notifications.length === 0 ? 'flex' : 'block',
          justifyContent:
            is_loading || notifications.length === 0 ? 'center' : 'start',
          alignItems:
            is_loading || notifications.length === 0 ? 'center' : 'start',
        }"
      >
        <!-- is loading -->
        <div v-if="is_loading" class="text-center">
          <div
            class="spinner-border text-primary"
            role="status"
            style="width: 3rem; height: 3rem"
            :style="{
              color: this.$store.state.darkMode
                ? '#fefefe !important'
                : '#1d4955 !important',
            }"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <!-- No notifications -->
        <div v-else-if="notifications.length === 0" class="text-center">
          <h4
            :style="{
              color: this.$store.state.darkMode
                ? '#fefefe !important'
                : '#1d4955 !important',
            }"
          >
            لا يوجد إشعارات
          </h4>
        </div>
        <NotificationItemComponent
          v-else
          v-for="(notification, index) in notifications"
          :key="index"
          :notification="notification"
        />
      </div>
    </div>
  </div>
</template>

<script>
import NotificationItemComponent from "../NotificationItem/NotificationItemComponent.vue";
export default {
  name: "NotificationsComponent",
  components: {
    NotificationItemComponent,
  },
  props: {
    is_loading: Boolean,
    notifications: Array,
  },
  methods: {
    formatDate(date) {
      return new Date(date).toLocaleDateString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
    },
  },
};
</script>

<style scoped>
.con,
.notifications {
  background-color: #ffffff;
  border-radius: 2rem;
  padding: 1rem;
  min-height: 50vh;
  max-height: 85vh;
  overflow: hidden;
}
.notifications {
  background-color: #ffffff;
  border-radius: 2rem;
  padding: 20px;
  min-height: 50vh;
  max-height: 80vh;
  overflow: auto;
  direction: ltr;
}
.notifications::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 0.5rem;
}
.notifications::-webkit-scrollbar-thumb {
  border-radius: 0.5rem;
  background-color: #c3c3c3;
  border: 1px solid #c3c3c3;
}
.notifications::-webkit-scrollbar-track {
  border-radius: 0.5rem;
  background-color: #eeeeee;
}
/* Dark Mode */
body.dark .con,
body.dark .notifications {
  background-color: #1d4955;
}
body.dark .notifications::-webkit-scrollbar-thumb {
  background-color: #235562;
  border: 1px solid #13323a;
}
body.dark .notifications::-webkit-scrollbar-track {
  background-color: #13323a;
}
</style>
