import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import 'nprogress/nprogress.css'

import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';

const app = createApp(App)
app.use(store)
app.use(router)
app.use(ElementPlus);
app.mount('#app')