const exportTableToExcel = (tableId, filename = "export") => {
  const table = document.getElementById(tableId);
  if (!table) {
    console.error(`Table with ID "${tableId}" not found.`);
    return;
  }

  const rows = Array.from(table.querySelectorAll("tr")); // تحويل NodeList إلى Array
  const csvContent = rows
    .map((row) => {
      const cols = Array.from(row.querySelectorAll("td, th"));
      return cols
        .map((col) => col.innerText.replace(/,/g, "،").replace(/"/g, '""'))
        .map((text) => `"${text}"`)
        .join(",");
    })
    .join("\n");

  const csvFile = `\uFEFF${csvContent}`;
  const blob = new Blob([csvFile], { type: "text/csv;charset=utf-8;" });
  const url = URL.createObjectURL(blob);

  const downloadLink = document.createElement("a");
  downloadLink.href = url;
  downloadLink.download = `${filename}_${new Date()
    .toISOString()
    .replace(/[:.]/g, "-")}.csv`;
  downloadLink.style.display = "none";
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

export default exportTableToExcel;
