<template>
  <div class="container">
    <div class="header">
      <div class="num-logout-day">
        <span class="title">عدد مرات تسجيل الخروج</span>
        <span class="count">1</span>
      </div>
      <div class="num-logout-week">
        <span class="title">عدد مرات تسجيل الخروج خلال الاسبوع</span>
        <span class="count">5</span>
      </div>
      <div class="num-logout-auto">
        <span class="title">عدد مرات تسجيل الخروج تلقائي</span>
        <span class="count">8</span>
      </div>
    </div>
    <div class="export-container">
      <button class="export-button" id="toEXCEL" @click="exportToExcel">
        تحميل ملف Exel
      </button>
    </div>
    <div class="table" id="customers_table">
      <div class="table__body">
        <table>
          <thead>
            <tr>
              <th>نوع الجهاز</th>
              <th>أسم الجهاز</th>
              <th>نظام التشغيل</th>
              <th>المتصفح</th>
              <th>أخر نشاط</th>
              <th>تاريخ تسجيل الدخول</th>
              <th>تاريخ تسجيل الخروج</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Desktop</td>
              <td>Unknown</td>
              <td>Windows 10</td>
              <td>Chrome 130</td>
              <td>00:17 الثلاثاء، ٥ نوفمبر ٢٠٢٤</td>
              <td>00:17 الثلاثاء، ٥ نوفمبر ٢٠٢٤</td>
              <td>00:17 الثلاثاء، ٥ نوفمبر ٢٠٢٤</td>
            </tr>
            <tr>
              <td>Desktop</td>
              <td>Unknown</td>
              <td>Windows 10</td>
              <td>Chrome 130</td>
              <td>00:17 الثلاثاء، ٥ نوفمبر ٢٠٢٤</td>
              <td>00:17 الثلاثاء، ٥ نوفمبر ٢٠٢٤</td>
              <td>00:17 الثلاثاء، ٥ نوفمبر ٢٠٢٤</td>
            </tr>
            <tr>
              <td>Desktop</td>
              <td>Unknown</td>
              <td>Windows 10</td>
              <td>Chrome 130</td>
              <td>00:17 الثلاثاء، ٥ نوفمبر ٢٠٢٤</td>
              <td>00:17 الثلاثاء، ٥ نوفمبر ٢٠٢٤</td>
              <td>00:17 الثلاثاء، ٥ نوفمبر ٢٠٢٤</td>
            </tr>
            <tr>
              <td>Desktop</td>
              <td>Unknown</td>
              <td>Windows 10</td>
              <td>Chrome 130</td>
              <td>00:17 الثلاثاء، ٥ نوفمبر ٢٠٢٤</td>
              <td>00:17 الثلاثاء، ٥ نوفمبر ٢٠٢٤</td>
              <td>00:17 الثلاثاء، ٥ نوفمبر ٢٠٢٤</td>
            </tr>
            <tr>
              <td>Desktop</td>
              <td>Unknown</td>
              <td>Windows 10</td>
              <td>Chrome 130</td>
              <td>00:17 الثلاثاء، ٥ نوفمبر ٢٠٢٤</td>
              <td>00:17 الثلاثاء، ٥ نوفمبر ٢٠٢٤</td>
              <td>00:17 الثلاثاء، ٥ نوفمبر ٢٠٢٤</td>
            </tr>
            <tr>
              <td>Desktop</td>
              <td>Unknown</td>
              <td>Windows 10</td>
              <td>Chrome 130</td>
              <td>00:17 الثلاثاء، ٥ نوفمبر ٢٠٢٤</td>
              <td>00:17 الثلاثاء، ٥ نوفمبر ٢٠٢٤</td>
              <td>00:17 الثلاثاء، ٥ نوفمبر ٢٠٢٤</td>
            </tr>
            <tr>
              <td>Desktop</td>
              <td>Unknown</td>
              <td>Windows 10</td>
              <td>Chrome 130</td>
              <td>00:17 الثلاثاء، ٥ نوفمبر ٢٠٢٤</td>
              <td>00:17 الثلاثاء، ٥ نوفمبر ٢٠٢٤</td>
              <td>00:17 الثلاثاء، ٥ نوفمبر ٢٠٢٤</td>
            </tr>
            <tr>
              <td>Desktop</td>
              <td>Unknown</td>
              <td>Windows 10</td>
              <td>Chrome 130</td>
              <td>00:17 الثلاثاء، ٥ نوفمبر ٢٠٢٤</td>
              <td>00:17 الثلاثاء، ٥ نوفمبر ٢٠٢٤</td>
              <td>00:17 الثلاثاء، ٥ نوفمبر ٢٠٢٤</td>
            </tr>
            <tr>
              <td>Desktop</td>
              <td>Unknown</td>
              <td>Windows 10</td>
              <td>Chrome 130</td>
              <td>00:17 الثلاثاء، ٥ نوفمبر ٢٠٢٤</td>
              <td>00:17 الثلاثاء، ٥ نوفمبر ٢٠٢٤</td>
              <td>00:17 الثلاثاء، ٥ نوفمبر ٢٠٢٤</td>
            </tr>
            <tr>
              <td>Desktop</td>
              <td>Unknown</td>
              <td>Windows 10</td>
              <td>Chrome 130</td>
              <td>00:17 الثلاثاء، ٥ نوفمبر ٢٠٢٤</td>
              <td>00:17 الثلاثاء، ٥ نوفمبر ٢٠٢٤</td>
              <td>00:17 الثلاثاء، ٥ نوفمبر ٢٠٢٤</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import exportToExcel from "../../../services/csv";
export default {
  name: "SecurityLoginHistoryComponent",
  components: {},
  data() {
    return {};
  },
  methods: {
    exportToExcel() {
      exportToExcel();
    },
  },
};
</script>

<style scoped>
.header {
  position: relative;
  width: 75%;
  background-color: #f0f0f0;
  box-shadow: 0 0.4rem 0.8rem #0005;
  border-radius: 1.25rem;
  overflow: hidden;
  padding: 1rem 2rem;
  margin: 0 auto 2rem auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 2rem;
}
.header .num-logout-day,
.header .num-logout-week,
.header .num-logout-auto {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 1rem;
}
.header .num-logout-day .title,
.header .num-logout-week .title,
.header .num-logout-auto .title {
  font-size: 1rem;
  font-weight: 500;
  color: #000;
}
.header .num-logout-day .count,
.header .num-logout-week .count,
.header .num-logout-auto .count {
  background-color: #fff;
  color: #000;
  font-size: 1.25rem;
  font-weight: 500;
  padding: 0;
  width: 30px;
  height: 35px;
  border-radius: 0.4rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.export-container {
  text-align: center;
  margin-bottom: 2rem;
}
.export-button {
  background-color: #fff;
  color: #000;
  box-shadow: 0px 0px 2px 0px #000000;
  padding: 1rem 2rem;
  border-radius: 30px;
  cursor: pointer;
  border: none;
  outline: none;
  font-size: 1rem;
  transition: all 0.3s;
}
.export-button:hover {
  background-color: #038c4c;
  color: #fff;
}
.table > :not(caption) > * > * {
  background-color: transparent;
}
.table {
  position: relative;
  width: 100%;
  height: 75vh;
  background-color: #fff;
  box-shadow: 0 0.4rem 0.8rem #0005;
  border-radius: 1.25rem;
  overflow: hidden;
  padding: 2rem;
  direction: ltr;
}
.table__body {
  background-color: #fff;
  width: 100%;
  height: 100%;
  border-radius: 1.25rem;
  padding: 0 2rem 2rem 2rem;
  overflow: auto;
  overflow: overlay;
}
.table__body::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}
.table__body::-webkit-scrollbar-thumb {
  border-radius: 0.5rem;
  background-color: #c3c3c3;
  border: 1px solid #c3c3c3;
}
.table__body::-webkit-scrollbar-track {
  background-color: #eeeeee;
}
table {
  width: 100%;
  direction: rtl;
}
table,
th,
td {
  border-collapse: collapse;
  padding: 1rem 0.5rem;
  text-align: center;
  min-width: 190px;
  min-height: 31px;
}
thead th {
  position: sticky;
  top: 0;
  /* left: 0; */
  z-index: 1;
  background-color: #fff;
  color: #000;
  border: none;
  border-bottom: 0.1rem solid #00000020;
}
tbody tr {
  background-color: #fff;
  border: none;
  border-bottom: 0.1rem solid #00000010;
}
th {
  color: #000;
  font-weight: 500;
}
td {
  color: #00000070;
  font-weight: 300;
}
/* Dark Mode */
body.dark .header,
body.dark th,
body.dark .header .num-logout-day .title,
body.dark .header .num-logout-week .title,
body.dark .header .num-logout-auto .title {
  color: #fff;
}
body.dark .header .num-logout-day .count,
body.dark .header .num-logout-week .count,
body.dark .header .num-logout-auto .count {
  background-color: #13323a;
  color: #fff;
}
body.dark .export-button {
  background-color: #1d4955;
  color: #fff;
}
body.dark .export-button:hover {
  background-color: #038c4c;
}
body.dark .table__body::-webkit-scrollbar-thumb {
  background-color: #235562;
  border: 1px solid #13323a;
}
body.dark .table__body::-webkit-scrollbar-track {
  background-color: #13323a;
}
body.dark .header,
body.dark tbody tr,
body.dark .table__body,
body.dark .table {
  background-color: #1d4955;
}
body.dark thead th {
  background-color: #1d4955;
  color: #fff;
  border: none;
  border-bottom: 0.1rem solid #ffffff20;
}
body.dark td {
  color: #ffffff70;
}
/*  Media queries */
@media screen and (max-width: 1200px) {
  .header {
    width: 100%;
    flex-direction: column;
    gap: 1rem;
  }
  .header .num-logout-day,
  .header .num-logout-week,
  .header .num-logout-auto {
    min-width: 100%;
    align-items: center;
    justify-content: space-between;
  }
}
@media screen and (max-width: 992px) {
  .header .num-logout-day .count,
  .header .num-logout-week .count,
  .header .num-logout-auto .count {
    font-size: 1rem;
    padding: 1rem;
  }
  .export-button {
    padding: 0.75rem 1.5rem;
    font-size: 0.9rem;
  }
  .table {
    padding: 1rem;
  }
  .table__body {
    padding: 0 1rem 1rem 1rem;
  }
  .header .num-logout-day .title,
  .header .num-logout-week .title,
  .header .num-logout-auto .title {
    font-size: 0.8rem;
  }
  th,
  td {
    font-size: 0.9rem;
  }
}
</style>
