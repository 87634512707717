<template lang="">
  <div v-if='$store.state.isAuthenticated' class="profile-navigation" :class="{ active: active }">
    <div class="open-container" @click="openMenu">
      <AkChevronDown class="open" />
    </div>
    <ul class="container mb-0 p-0">
      <li @click="closeMenu">
        <router-link
          to="/user-profile"
          exact
          active-class="active"
          :class="{ active: $route.path === '/profile' }"
        >
          ملف المستخدم
        </router-link>
      </li>
      <li @click="closeMenu">
        <router-link :to="{name:'user_courses'}" active-class="active">
          كورساتي
        </router-link>
      </li>
      <li @click="closeMenu">
        <router-link :to="{ name: 'coursesList' }" active-class="active">
          جميع الكورسات
        </router-link>
      </li>
      <!-- <li @click="closeMenu">
        <router-link to="/user-profile/history" active-class="active">
          الآمان و تاريخ تسجيل الدخول
        </router-link>
      </li> -->
      <li @click="closeMenu">
        <router-link to="/user-profile/details-views" active-class="active">
          تفاصيل المشاهدات
        </router-link>
      </li>
      <li @click="closeMenu">
        <router-link to="/user-profile/bills" active-class="active">
          الفواتير
        </router-link>
      </li>
      <li @click="closeMenu">
        <router-link to="/user-profile/subscriptions" active-class="active">
          الاشتراكات
        </router-link>
      </li>
      <li @click="closeMenu">
        <router-link to="/user-profile/exams-results" active-class="active">
          نتائج الامتحانات
        </router-link>
      </li>
      <li @click="closeMenu">
        <router-link
          to="/user-profile/assignment-results"
          active-class="active"
        >
          نتائج الواجب
        </router-link>
      </li>
      <!-- <li @click="closeMenu">
        <router-link
          to="/user-profile/video-quiz-results"
          active-class="active"
        >
          نتائج كويز الفيديو
        </router-link>
      </li> -->
      <li @click="closeMenu">
        <router-link to="/user-profile/notifications" active-class="active">
          الاشعارات
        </router-link>
      </li>
      <li @click="closeMenu">
        <router-link to="/user-profile/news" active-class="active">
          الاخبار
        </router-link>
      </li>
      <li @click="closeMenu">
        <router-link to="/user-profile/level-indicator" active-class="active">
          مؤشر المستوى
        </router-link>
      </li>
      <div class="close-container" @click="closeMenu">
        <AkChevronDown class="close" />
      </div>
    </ul>
  </div>
  <router-view />
</template>
<script>
import { AkChevronDown } from "@kalimahapps/vue-icons";
export default {
  name: "ProfileLayout",
  components: {
    AkChevronDown,
  },
  data() {
    return {
      active: false,
    };
  },
  methods: {
    openMenu() {
      this.active = true;
    },
    closeMenu() {
      this.active = false;
    },
  },
};
</script>
<style scoped>
.profile-navigation {
  background-color: #000000;
  position: relative;
  min-height: 50px;
}
.profile-navigation svg {
  display: none;
}
.profile-navigation ul {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  width: 100%;
}
.profile-navigation ul li {
  min-width: 50px;
  width: fit-content;
}
.profile-navigation ul li a {
  all: unset;
  padding: 15px;
  color: #fff;
  text-align: center;
  cursor: pointer;
  display: block;
  transition: all 0.5s;
}
.profile-navigation ul li a.active,
.profile-navigation ul li a:hover {
  background-image: linear-gradient(#de4931 100%, #f58437 100%);
}
/*  Media Queries */
@media (max-width: 992px) {
  .profile-navigation {
    min-height: 75px;
  }
  .profile-navigation svg {
    display: block;
    color: #fff;
    font-size: 2rem;
    cursor: pointer;
  }
  .profile-navigation.active .open-container {
    display: none;
  }
  .profile-navigation .open-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }
  .profile-navigation svg.close {
    rotate: 180deg;
  }
  .profile-navigation .close-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 0;
    cursor: pointer;
  }
  .profile-navigation ul {
    flex-direction: column;
    max-width: 100%;
    height: 0vh;
    opacity: 0;
    overflow: hidden;
    transition: hight 0.3s;
  }
  .profile-navigation.active ul {
    flex-direction: column;
    opacity: 1;
    height: auto;
    transition: hight 0.3s;
  }
  .profile-navigation.active ul li {
    width: 100%;
    text-align: center;
  }
}
</style>
