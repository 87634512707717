<template>
  <div class="error">
    <h2 v-if="error === 404">الصفحة غير موجودة</h2>
    <h2 v-else-if="error === 500">خطأ في الخادم</h2>
    <h2 v-else-if="error === 403">غير مسموح لك بالوصول لهذه الصفحة</h2>
    <h2 v-else-if="error === 401">يجب عليك تسجيل الدخول</h2>
    <h2 v-else-if="error === 400">طلب غير صحيح</h2>
    <h2 v-else-if="error === 429">تم استهلاك الحد الأقصى من الطلبات</h2>
    <h2 v-else-if="error === 405">الطريقة غير مسموحة</h2>
    <h2 v-else-if="error === 503">الخادم غير متاح</h2>
    <h2 v-else-if="error === 504">انتهى الوقت المسموح للطلب</h2>
    <h2 v-else-if="error === 502">خطأ في الخادم</h2>
    <h2 v-else-if="error === 408">انتهى الوقت المسموح للطلب</h2>
    <h2 v-else-if="error === 409">يوجد خطأ في البيانات</h2>
    <h2 v-else-if="error === 410">المحتوى غير متوفر</h2>
    <h2 v-else>خطأ غير معروف</h2>
  </div>
</template>

<script>
export default {
  name: "ErrorComponent",
  props: {
    error: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style scoped>
.error {
  width: 100%;
  padding: 1rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #000;
  font-size: 1.5rem;
  font-weight: 700;
}
body.dark .error {
  color: #fff;
}
</style>
