<!-- <template>
  <div
    class="cards-container d-flex flex-wrap justify-content-center align-items-center"
  >
    <div class="card" v-for="(card, index) in cards" :key="index">
      <img class="card-image" :src="card.imgSrc" alt="Card Image" />
      <div class="card-content">
        <div class="default-text">
          <h3>{{ card.text }}</h3>
        </div>
        <div class="hover-content d-flex flex-column align-items-center">
          <h2>{{ card.text }}</h2>
          <p>
            هنتعرف على العناصر الانتقالية واستخدامات عناصر السلسة الانتقالية
            الاولى وكمان حالات تأكسدها وخواصها وكمان هنتكلم عن الحديد وتحضيره
            واهم مركباته
          </p>
          <button>المزيد</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CardComponent",
  data() {
    return {
      cards: [
        {
          imgSrc: require("@/assets/MGAssets/cr1.png"),
          text: "كورساتك الشهرية",
        },
        { imgSrc: require("@/assets/MGAssets/cr2.png"), text: "عروض متفوتهاش" },
        {
          imgSrc: require("@/assets/MGAssets/cr3.png"),
          text: "مفرمة انقاذ لكل باب",
        },
        {
          imgSrc: require("@/assets/MGAssets/cr4.png"),
          text: "تقدر تتابعنا على الyoutube",
        },
      ],
    };
  },
};
</script> -->

<template>
  <div
    class="cards-container d-flex flex-wrap justify-content-center align-items-center gap-3"
  >
    <div class="card" v-for="(card, index) in cards" :key="index">
      <img class="card-image" :src="card.imgSrc" alt="Card Image" />
      <div class="card-content">
        <div class="default-text">
          <h3>{{ card.text }}</h3>
        </div>
        <div class="hover-content d-flex flex-column align-items-center">
          <h2>{{ card.text }}</h2>
          <p>
            {{ card.desc }}
          </p>
          <button @click="navigateTo(card.route)">المزيد</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardComponent",
  data() {
    return {
      cards: [
        {
          imgSrc: require("@/assets/MGAssets/cr1.png"),
          text: "كورساتك الشهرية",
          desc: ' تقدر تتابع كل كورساتك الشهريه من هنا ',
          route: "coursesListout",
        },
        {
          imgSrc: require("@/assets/MGAssets/cr2.png"),
          text: "عروض متفوتهاش",
          desc: ' تقدر تشوف كل العروض الجديدة و تتابعها من هنا ' ,
          route: "coursesListout",
        },
        {
          imgSrc: require("@/assets/MGAssets/cr3.png"),
          text: "مفرمة انقاذ لكل باب",
          desc: ' تقدر تتابع المفرمه اللي عملنهالك لكل باب من هنا ' ,
          route: "coursesListout",
        },
        {
          imgSrc: require("@/assets/MGAssets/cr4.png"),
          text: "تقدر تتابعنا على الyoutube",
          desc: ' تقدر تتابعنا و تشوف اخر فيديوهاتنا علي يوتيوب من هنا  ' ,
          route: "https://www.youtube.com/@mahmoudelgharabawy4195",
        },
      ],
    };
  },
  methods: {
    navigateTo(route) {
      if (route.startsWith("http")) {
        window.open(route, "_blank");
      } else {
        this.$router.push({name: route});
      }
    },
  },
};
</script>

<style scoped>
.cards-container {
  /* display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px; */

  /* position: absolute; */
  width: 100%;
  height: auto;
  /* left: 0px; */
  /* top: 1325px; */
  box-sizing: border-box;
}

.card {
  position: relative;
  min-width: 350px; /* Adjust the card width */
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.card-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card-content {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20%; /* Default height for the text area */
  background-color: #f36e21; /* Orange background */
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease-in-out;
}

.card:hover .card-content {
  height: 100%; /* Expand to full height on hover */
}

.default-text {
  /* عروض متفوتهاش */

  /* left: 27.78%; */
  /* right: 27.78%; */
  position: relative;
  top: 100%;
  /* bottom: 5.72%; */

  font-family: "Cairo";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 163%;
  /* identical to box height, or 33px */
  text-align: right;

  color: #ffffff;

  font-size: 18px;
  font-weight: bold;
  transition: opacity 0.3s ease-in-out;
}

.card:hover .default-text {
  opacity: 0; /* Hide the default text on hover */
}

.hover-content {
  opacity: 0;
  text-align: center;
  transition: opacity 0.3s ease-in-out;
}

.card:hover .hover-content {
  opacity: 1; /* Show the hover content on hover */
}

.hover-content h2 {
  font-size: 20px;
  margin-bottom: 15px;
  font-weight: 500;
}

.hover-content p {
  margin-bottom: 15px;
  width: 75%;
  font-family: "Cairo";
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 163%;
}

.hover-content button {
  padding: 10px 20px;
  color: #000;
  cursor: pointer;
  font-weight: bold;

  box-sizing: border-box;
  font-size: 12px;
  width: 120px;
  height: 41px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}
@media (max-width: 450px) {
  .card {
    width: 100%; /* Adjust the card width */
  }

  .cards-container {
    width: 100%;
    height: 2000px;
    box-sizing: border-box;
  }
}
</style>