<template >
    <div class="pagination" >
        <button class="prev" @click="change_page_prev" :class="{disable:!prev}" :disabled="!prev"> السابق </button>
        <button class="next" @click="change_page_next" :class="{disable:!next}" :disabled="!next"> التالي </button>
    </div>
</template>
<script>
export default {
    name:'PaginationBtn' ,
    props: ['next' , 'prev' , 'link'] ,
    methods: {
        change_page_next(){
            this.$router.push({name: this.link , query: {page: (parseInt(this.$route.query.page) + 1 || 2)}})
        } ,
        change_page_prev() {
            this.$router.push({name: this.link , query: {page: (parseInt(this.$route.query.page) - 1)}})
        }
    }
}
</script>
<style scoped>
    .pagination {
        padding: 0.8rem 0;
        display: flex ;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    .pagination button {
        all: unset;
        padding: 0.5rem 1rem;
        border: 1px solid #00000033;
        text-align: center;
        border-radius: 0.8rem;
        cursor: pointer;
    }
    .pagination button:first-of-type {
        margin-left: 0.8rem;
    }
    .pagination button.disable {
        opacity: 0.5;
        cursor:no-drop;
    }
</style>