<template>
  <div
    class="notification-item"
    :class="{
      read: notification?.is_read === true,
      unread: notification?.is_read === false,
    }"
  >
    <div class="notification-item-header" v-if="notification?.image">
      <img
        :src="notification?.image"
        :alt="notification?.title || 'Notification'"
      />
    </div>
    <div class="notification-item-body">
      <h3 class="title" v-if="notification?.title">
        {{ notification?.title }}
      </h3>
      <p class="desc" v-if="notification?.text">
        {{ notification?.text }}
      </p>
      <hr />
      <p class="date" v-if="notification?.created_at">
        {{ formatDate(notification?.created_at) }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotificationItemComponent",
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  methods: {
    formatDate(date) {
      return new Date(date).toLocaleDateString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
    },
  },
};
</script>

<style scoped>
.notification-item {
  display: flex;
  margin-bottom: 0.5rem;
  direction: rtl;
  padding: 0.5rem;
}
.notification-item.unread {
  background-color: #f9f9f9;
}
body.dark .notification-item.unread {
  background-color: #f9f9f910;
}
.notification-item.unread * {
  font-weight: 700 !important;
}
.notification-item.read {
  background-color: transparent;
}
.notification-item.read * {
  font-weight: 300 !important;
}
.notification-item-header {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  padding: 1px;
  background-color: #efefef;
}
.notification-item-header img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.notification-item-body {
  padding: 0 1rem;
  width: 100%;
}
.notification-item-body .title {
  font-size: 1.1rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
  color: #1e1e1e;
}
.notification-item-body .desc {
  font-size: 1rem;
  font-weight: 500;
  color: #1e1e1e;
  margin-bottom: 0.5rem;
}
.notification-item-body hr {
  margin: 0.5rem 0;
  border: none;
  border-top: 1.25px solid #00000080;
}
.notification-item-body .date {
  font-size: 0.9rem;
  font-weight: 500;
  color: #1e1e1e90;
}
/* Dark Mode */
body.dark .notification-item-body .title,
body.dark .notification-item-body .desc {
  color: #e1e1e1;
}
body.dark .notification-item-body .date {
  color: #e1e1e1;
}
body.dark hr {
  border-top: 1.25px solid #ffffff80;
}
</style>
