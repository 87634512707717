<template>
  <div class="contact-support" @click="go_to_support_whatsApp">
    <AkWhatsappFill />
  </div>
</template>
<script>
import { AkWhatsappFill } from "@kalimahapps/vue-icons";
export default {
  name: "ContactSupportComponent",
  components: {
    AkWhatsappFill,
  },
  methods: {
    go_to_support_whatsApp() {
      window.location.replace(
        "https://api.whatsapp.com/send?phone=201113533306"
      );
    },
  },
};
</script>
<style scoped>
.contact-support {
  position: fixed;
  bottom: 20px;
  left: 20px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #25d366;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 1.5rem;
  z-index: 9999999999999999;
}
/* .contact-support::after {
        content: "";
        text-align: center;
        transition: all 0.5s;
        overflow: hidden;
        padding: 0.5rem 1rem;
        text-align: center;
        position: absolute;
        left: 0;
    } */
</style>
