<template>
  <div class="container">
    <div class="platform-statistics-box-container">
      <div
        class="platform-statistics-box-item"
        v-for="(platform, index) in platformStatistics"
        :key="index"
      >
        <h3 class="title">{{ platform.name }}</h3>
        <div class="line-con">
          <span class="line d-lg-block d-none"></span>
          <span
            class="statistics-container"
            :style="{ backgroundColor: platform.color }"
          >
            {{
              platform.isTime
                ? platform.count + " ساعة"
                : platform.count + " مرة"
            }}
            <PhFillClockCountdown
              v-if="platform.isTime"
              style="color: platform.color"
            />
            <PhFillFlagBannerFold v-else style="color: platform.color" />
          </span>
        </div>
        <div class="separator"></div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  PhFillClockCountdown,
  PhFillFlagBannerFold,
} from "@kalimahapps/vue-icons";
export default {
  name: "platformStatisticsComponent",
  components: {
    PhFillClockCountdown,
    PhFillFlagBannerFold,
  },
  data() {
    return {
      platformStatistics: [
        {
          name: "إجمالي مدة فتح المحاضرات علي الموقع",
          color: "#2abf7a",
          count: 40,
          isTime: true,
        },
        {
          name: "إجمالي عدد مرات مشاهدة الفيديوهات علي الموقع",
          color: "#e71c39",
          count: 126,
          isTime: false,
        },
        {
          name: "اجمالي عدد مرات فتح الاختبار",
          color: "#9747ff",
          count: 9,
          isTime: false,
        },
        {
          name: "اجمالي عدد مرات إنهاء الاختبارات",
          color: "#9747ff",
          count: 9,
          isTime: false,
        },
      ],
    };
  },
};
</script>

<style scoped>
.platform-statistics-box-container {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  padding: 3rem 2rem;
  border-radius: 50px;
  margin: 0 auto;
  box-shadow: 0 0 10px 0 #00000010;
  gap: 2rem;
  overflow: hidden;
}
.statistics-container {
  min-width: 143px;
  min-height: 33px;
  display: block;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 28px;
  color: #fff;
  font-size: 1.25rem;
}
.platform-statistics-box-item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}
.title {
  font-size: 1.25rem;
  font-weight: 500;
  color: #000;
  margin: 0;
  position: relative;
}
.line-con {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
}
.line {
  width: 100%;
  height: 1px;
  border: 1px dashed #00000020;
}
.platform-statistics-box-item:nth-of-type(1) .line-con {
  width: calc(100% - 315px);
}
.platform-statistics-box-item:nth-of-type(2) .line-con {
  width: calc(100% - 410px);
}
.platform-statistics-box-item:nth-of-type(3) .line-con {
  width: calc(100% - 235px);
}
.platform-statistics-box-item:nth-of-type(4) .line-con {
  width: calc(100% - 265px);
}
.platform-statistics-box-item .separator {
  display: none;
}
/* Dark Mode */
body.dark .platform-statistics-box-container {
  background-color: #1d4955;
}
body.dark .average,
body.dark .statistics-prog-container {
  background-color: #13323a;
}
body.dark .title,
body.dark .bottom span {
  color: #fff;
}
body.dark .line {
  border: 1px dashed #ffffff20;
}
body.dark .platform-statistics-box-item:not(:last-of-type) .separator {
  border: 1px solid #ffffff20;
}
/*  Media queries */
@media screen and (max-width: 992px) {
  .platform-statistics-box-container {
    width: 100%;
  }
  .platform-statistics-box-item {
    flex-direction: column;
    gap: 1.5rem;
    width: 100%;
  }
  .platform-statistics-box-item .statistics-container {
    width: 100%;
    font-size: 1rem;
  }
  .line-con {
    justify-content: center;
    width: 100% !important;
  }
  .statistics-container {
    width: 100% !important;
  }
  .title {
    font-size: 1rem;
    text-align: center;
  }
  .platform-statistics-box-item:not(:last-of-type) .separator {
    width: 80%;
    height: 1px;
    border: 1px solid#00000020;
    display: block;
  }
}
</style>
