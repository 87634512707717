<template>
  <div class="exam-student-answers-container">
    <div class="header">
      <h1 class="header-title">الإجابات</h1>
    </div>
    <!-- Loading -->
    <LoadingComponent v-if="is_loading" class="mt-5" />
    <!-- Error -->
    <ErrorComponent
      v-else-if="error && !is_loading"
      :error="error"
      class="mt-5"
    />
    <!-- No data -->
    <div class="no-data-container" v-else-if="student_answers?.length === 0">
      <h2>لا يوجد بيانات</h2>
    </div>
    <div class="body" v-else>
      <div class="head">
        <div class="top">
          <div class="num-questions">
            <span>عدد الأسئلة</span>
            <span class="num">{{ answers?.exam_score }}</span>
          </div>
          <div class="num-answered-questions">
            <span>عدد الأسئلة المحلولة</span>
            <span class="num">{{ answers?.student_answers?.length }}</span>
          </div>
          <div class="result">
            <span>النتيجة</span>
            <span
              class="num"
              :class="{
                success: answers?.is_succeeded,
                failed: !answers?.is_succeeded,
              }"
            >
              %
              {{
                Math.round((answers?.student_score / answers?.exam_score) * 100)
              }}
            </span>
          </div>
        </div>
        <div class="bottom">
          <div class="num-correct-answers">
            <span>عدد الإجابات الصحيحة</span>
            <span class="num">{{ answers?.student_score }}</span>
          </div>
          <div class="num-wrong-answers">
            <span>عدد الإجابات الخاطئة</span>
            <span class="num">{{ num_wrongs }}</span>
          </div>
          <div class="result">
            <span>النتيجة</span>
            <span
              class="num"
              :class="{
                success: answers?.is_succeeded,
                failed: !answers?.is_succeeded,
              }"
            >
              %
              {{
                Math.round((answers?.student_score / answers?.exam_score) * 100)
              }}
            </span>
          </div>
        </div>
      </div>
      <div class="separator"></div>
      <div class="body-body">
        <ExamStudentAnswerItemComponent
          v-for="(answer, index) in student_answers"
          :key="answer.id"
          :data="answer"
          :number_question="index + 1"
        />
      </div>
    </div>
  </div>
</template>

<script>
import LoadingComponent from "@/components/Loading/LoadingComponent.vue";
import ErrorComponent from "@/components/Error/ErrorComponent.vue";
import ExamStudentAnswerItemComponent from "@/components/Profile/ExamStudentAnswerItem/ExamStudentAnswerItemComponent.vue";
import config from "@/services/config";
import axios from "axios";
export default {
  name: "ExamStudentAnswersView",
  components: {
    LoadingComponent,
    ErrorComponent,
    ExamStudentAnswerItemComponent,
  },
  data() {
    return {
      student_answers: [],
      answers: {},
      num_wrongs: 0,
      is_loading: true,
      error: null,
    };
  },
  methods: {
    getAnswers() {
      axios
        .get(`${config.baseURL}exams/${this.$route?.params?.id}/result/`, {
          headers: {
            auth: this.$store.state.userToken,
          },
        })
        .then((res) => {
          this.answers = res?.data;
          this.student_answers = res?.data?.student_answers?.map((answer) => {
            return {
              ...answer,
              correct_answers: res?.data?.correct_answers?.find(
                (correctAnswer) =>
                  correctAnswer.question_id === answer.question_id
              )?.correct_answers[0],
            };
          });
          this.num_wrongs = this.student_answers
            ?.map((answer) => (answer?.is_correct === false ? 1 : 0))
            ?.reduce((acc, curr) => acc + curr, 0);
        })
        .catch((err) => {
          console.log(err.message);
          this.error = err?.response?.status
            ? err.response.status
            : err.message;
        })
        .finally(() => {
          this.is_loading = false;
        });
    },
  },
  created() {
    this.getAnswers();
  },
};
</script>

<style scoped>
.no-data-container,
.loading-container {
  margin-top: 5rem;
}
.no-data-container h2 {
  text-align: center;
  font-size: 1.5rem;
  font-weight: 600;
  color: #000000;
}
body.dark .no-data-container h2 {
  color: #ffffff;
}
.exam-student-answers-container {
  min-height: 100vh;
  background-color: #f9f9f9;
}
.exam-student-answers-container {
  min-height: 100vh;
  background-color: #f9f9f9;
}
body.dark .exam-student-answers-container {
  background-color: #13323a;
}
.exam-student-answers-container .header {
  width: 100%;
  height: 115px;
  /* background-image: url("../../assets/DALL·E 2024-12-11 01.58.57 - A seamless Arabic geometric patterns background with soft and comfortable colors for the eyes. The design features intricate Islamic motifs with inter.webp"); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}
body.dark .exam-student-answers-container .header .header-title {
  background-color: #13323a;
  border: 1px solid #ffffff33;
  color: #ffffff;
}
.exam-student-answers-container .header .header-title {
  background-color: #f9f9f9;
  border: 1px solid #00000033;
  color: #000000;
  padding: 0.7rem 6.8rem;
  border-radius: 2.5rem;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 700;
  position: absolute;
  bottom: -50%;
  right: 50%;
  transform: translate(50%, -50%);
}
.exam-student-answers-container .body {
  padding: 5rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.exam-student-answers-container .body .head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  width: 615px;
}
body.dark .exam-student-answers-container .body .head .bottom,
body.dark .exam-student-answers-container .body .head .top {
  background-color: #1d4955;
}
.exam-student-answers-container .body .head .bottom,
.exam-student-answers-container .body .head .top {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 0.5rem;
  background-color: #f0f0f0;
  border-radius: 1rem;
}
.exam-student-answers-container .body .head .bottom {
  justify-content: space-between;
}
body.dark
  .exam-student-answers-container
  .body
  .head
  .bottom
  .num-correct-answers,
body.dark
  .exam-student-answers-container
  .body
  .head
  .bottom
  .num-wrong-answers {
  color: #ffffff;
}
.exam-student-answers-container .body .head .bottom .num-correct-answers,
.exam-student-answers-container .body .head .bottom .num-wrong-answers {
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #000000;
}
body.dark
  .exam-student-answers-container
  .body
  .head
  .bottom
  .num-correct-answers {
  border-left: 1px solid #ffffff33;
}
.exam-student-answers-container .body .head .bottom .num-correct-answers {
  padding-left: 2rem;
  border-left: 1px solid #00000033;
}
body.dark .exam-student-answers-container .body .head .top .num-questions,
body.dark
  .exam-student-answers-container
  .body
  .head
  .top
  .num-answered-questions,
body.dark .exam-student-answers-container .body .head .top .result {
  color: #ffffff;
}
.exam-student-answers-container .body .head .top .num-questions,
.exam-student-answers-container .body .head .top .num-answered-questions,
.exam-student-answers-container .body .head .top .result {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  color: #000000;
}
.exam-student-answers-container .body .head .top .num-answered-questions {
  padding-left: 2rem;
  padding-right: 2rem;
  border-left: 1px solid #00000033;
  border-right: 1px solid #00000033;
}
body.dark
  .exam-student-answers-container
  .body
  .head
  .top
  .num-answered-questions {
  border-left: 1px solid #ffffff33;
  border-right: 1px solid #ffffff33;
}
.exam-student-answers-container .body .head .bottom .num-correct-answers span,
.exam-student-answers-container .body .head .bottom .num-wrong-answers span,
.exam-student-answers-container .body .head .top .num-questions span,
.exam-student-answers-container .body .head .top .num-answered-questions span,
.exam-student-answers-container .body .head .top .result span {
  font-size: 1rem;
  font-weight: 600;
}
body.dark
  .exam-student-answers-container
  .body
  .head
  .bottom
  .num-correct-answers
  .num,
body.dark
  .exam-student-answers-container
  .body
  .head
  .bottom
  .num-wrong-answers
  .num,
body.dark .exam-student-answers-container .body .head .top .num-questions .num,
body.dark
  .exam-student-answers-container
  .body
  .head
  .top
  .num-answered-questions
  .num,
body.dark .exam-student-answers-container .body .head .top .result .num {
  background-color: #13323a;
  color: #ffffff;
}
.exam-student-answers-container .body .head .bottom .result .num,
.exam-student-answers-container .body .head .bottom .num-correct-answers .num,
.exam-student-answers-container .body .head .bottom .num-wrong-answers .num,
.exam-student-answers-container .body .head .top .num-questions .num,
.exam-student-answers-container .body .head .top .num-answered-questions .num,
.exam-student-answers-container .body .head .top .result .num {
  font-size: 1.25rem;
  font-weight: 500;
  background-color: #ffffff;
  color: #000000;
  padding: 0.5rem 0.5rem;
  border-radius: 0.625rem;
}
body.dark .exam-student-answers-container .body .head .top .result {
  background-color: #13323a;
}
.exam-student-answers-container .body .head .top .result {
  background-color: #fff;
  padding-right: 0.5rem;
  border-radius: 0.625rem;
}
.exam-student-answers-container .body .head .bottom .num-wrong-answers {
  padding-right: 2rem;
}
body.dark
  .exam-student-answers-container
  .body
  .head
  .bottom
  .num-wrong-answers
  .num,
body.dark .exam-student-answers-container .body .head .top .result .num {
  background-color: #e9334e;
}
body.dark
  .exam-student-answers-container
  .body
  .head
  .bottom
  .num-wrong-answers
  .num.success,
body.dark
  .exam-student-answers-container
  .body
  .head
  .top
  .result
  .num.success {
  background-color: #2abf7a;
}
body.dark .exam-student-answers-container .body .head .top .result .num.failed {
  background-color: #038c4c;
}
.exam-student-answers-container .body .head .top .result .num.failed,
.exam-student-answers-container .body .head .bottom .result .num.failed,
.exam-student-answers-container .body .head .bottom .num-wrong-answers .num,
body.dark .exam-student-answers-container .body .bottom .result .num-success,
body.dark
  .exam-student-answers-container
  .body
  .head
  .bottom
  .num-wrong-answers
  .num,
body.dark .exam-student-answers-container .body .head .top .result .num.failed {
  background-color: #e71c39;
  color: #ffffff;
}
.exam-student-answers-container .body .head .bottom .result .num.success,
.exam-student-answers-container .body .head .top .result .num.success {
  background-color: #2abf7a;
  color: #ffffff;
}
body.dark
  .exam-student-answers-container
  .body
  .head
  .bottom
  .result
  .num.success,
body.dark .exam-student-answers-container .body .head .top .result .num.success,
body.dark
  .exam-student-answers-container
  .body
  .head
  .bottom
  .num-correct-answers
  .num {
  background-color: #038c4c;
}
.exam-student-answers-container .body .head .bottom .num-correct-answers .num {
  background-color: #2abf7a;
  color: #ffffff;
}
.exam-student-answers-container .body .separator {
  width: 100%;
  height: 1px;
  background-color: #00000033;
  margin: 4rem 0;
}
body.dark .exam-student-answers-container .body .separator {
  background-color: #ffffff33;
}
.exam-student-answers-container .body .body-body {
  width: 100%;
  padding: 0 8rem;
}
.exam-student-answers-container .body .head .bottom .result {
  display: none;
  width: 100%;
  padding: 0 2rem;
  color: #000000;
  font-size: 1rem;
  font-weight: 600;
}
body.dark .exam-student-answers-container .body .head .bottom .result {
  color: #ffffff;
}
body.dark
  .exam-student-answers-container
  .body
  .head
  .bottom
  .result
  .num.success {
  color: #ffffff;
  background-color: #038c4c;
}
.exam-student-answers-container .body .head .bottom .result .num.success {
  color: #ffffff;
  background-color: #e9334e;
  font-size: 1.25rem;
  font-weight: 500;
  padding: 0.5rem 0.5rem;
  border-radius: 0.625rem;
}
@media (max-width: 992px) {
  .exam-student-answers-container .body .body-body {
    padding: 0 4rem;
  }
}
@media (max-width: 768px) {
  .exam-student-answers-container .header {
    background-image: none;
  }
  .exam-student-answers-container .body {
    padding: 2rem 0;
  }
  .exam-student-answers-container .body .head {
    gap: 0rem;
    width: 100%;
  }
  .exam-student-answers-container .body .head .bottom,
  .exam-student-answers-container .body .head .top {
    flex-direction: column;
    gap: 1rem;
    border-radius: 0;
  }
  .exam-student-answers-container .body .head .bottom .result {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .exam-student-answers-container .body .head .top .num-questions,
  .exam-student-answers-container .body .head .top .num-answered-questions,
  .exam-student-answers-container .body .head .top .result {
    justify-content: space-between;
    padding: 0 2rem;
    width: 100%;
  }
  .exam-student-answers-container .body .head .top .result {
    display: none;
  }
  .exam-student-answers-container .body .head .bottom .num-wrong-answers,
  .exam-student-answers-container .body .head .bottom .num-correct-answers,
  body.dark
    .exam-student-answers-container
    .body
    .head
    .bottom
    .num-correct-answers {
    width: 100%;
    padding: 0 2rem;
    border-left: none;
  }
  .exam-student-answers-container .body .head .top .num-answered-questions,
  body.dark
    .exam-student-answers-container
    .body
    .head
    .top
    .num-answered-questions {
    border-left: none;
    border-right: none;
  }
  .exam-student-answers-container .header .header-title {
    padding: 0.7rem 3.5rem;
    font-size: 1.25rem;
    position: relative;
    width: fit-content;
    border: none;
  }
  body.dark .exam-student-answers-container .header .header-title {
    border: none;
  }
  body.dark .exam-student-answers-container .header::before {
    background-color: #ffffff33;
  }
  .exam-student-answers-container .header::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: #00000033;
    bottom: 50%;
    left: 0;
  }
  .exam-student-answers-container .body .body-body {
    margin: 4rem 0;
    padding: 0 2rem;
  }
  .exam-student-answers-container .body .separator {
    display: none;
  }
}
@media (max-width: 576px) {
  .exam-student-answers-container .body .body-body {
    padding: 0 1rem;
  }
}
</style>
