<template>
  <div class="home">
    <banner-component></banner-component>
    <ready-component></ready-component>
    <card-component></card-component>
    <feature-component></feature-component>
    <!-- <div class="line"></div> -->
    <!-- <phases-component></phases-component> -->
    <div class="line-2"></div>
    <special-watermelon-component></special-watermelon-component>
  </div>
</template>

<script>
// @ is an alias to /src
import {
  BannerComponent,
  ReadyComponent,
  CardComponent,
  FeatureComponent,
  // PhasesComponent,
  SpecialWatermelonComponent,
} from "@/components";
export default {
  name: "HomeView",
  components: {
    BannerComponent,
    ReadyComponent,
    CardComponent,
    FeatureComponent,
    // PhasesComponent,
    SpecialWatermelonComponent,
  },
};
</script>
<style scoped>
/* .home {
  height: 100%;
} */
.line {
  /* Line 2 */

  /* position: absolute; */
  min-width: 100%;
  height: 0px;
  /* left: -1342px; */
  /* top: 2422px; */
  overflow: hidden;

  border: 1px solid rgba(0, 0, 0, 0.1);
}
.line-2 {
  /* Line 3 */

  /* position: absolute; */
  min-width: 100%;
  height: 0px;
  /* left: -1342px; */
  /* top: 3005px; */
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
</style>
