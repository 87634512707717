<template>
  <div class="banner-container">
    <div class="container">
      <!-- right section -->
      <div class="right">
        <div class="teacher-image">
          <img src="@/assets/MGAssets/6.5a6c86e8.webp" alt="" loading="lazy">
        </div>
      </div>
      <!-- left section -->
      <div class="left">
        <div class="image">
          <img src="@/assets/MGAssets/leftbannerimg.png" alt="">
        </div>
        <h1>
          اهلا بيك ...
          <br>
          نورت مجتمع الغرباوية
        </h1>
        <p>د / محمود الغرباوى</p>
        <div class="btns">
          <button class="register-btn" @click="() => $router.push({name: 'register'})">انشاء حساب</button>
          <button class="login-btn" @click="() => $router.push({name: 'login'})">تسجيل الدخول</button>
        </div>
        <div class="rating d-flex align-items-center">
          <div class="student-images ms-3">
            <img src="@/assets/MGAssets/allimg.png" alt="" loading="lazy">
          </div>
          <div class="rate">
            <div class="top d-flex align-items-center">
              <img src="@/assets/MGAssets/stars.png" alt="" class="ms-2" loading="lazy">
              5.0
            </div>
            <div class="bottom" style="font-size: 11.09px;">انضم لاكثر من 15400+ طالب</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    };
  },
  computed: {
  },
};
</script>

<style scoped>
.banner-container {
  background-color: #fff;
}

.banner-container .container {
  height: 100vh;
  display: flex;
  align-items: center;
}

.banner-container .container .right,
.banner-container .container .left {
  width: 50%;
  padding: 1rem;
  height: 100%;
}

/* left section */

.banner-container .container .left .image {
  width: 50%;
  margin: 10px auto;
}

.banner-container .container .left .image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.banner-container .container .left .btns {
  padding: 1rem 0;
}

.banner-container .container .left .btns button {
  all: unset;
  padding: 0.5rem 1.5rem;
  border-radius: 10px;
  cursor: pointer;
  text-align: center;
}

.banner-container .container .left .btns button.register-btn {
  background-color: rgba(245, 132, 55, 1);
  color: #fff;
  margin-left: 1rem;
}

.banner-container .container .left .btns button.login-btn {
  background-color: rgba(255, 255, 255, 1);
  color: #000;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

/* right section */

.banner-container .container .right {
  background-image: url('@/assets/MGAssets/graph.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center ;
  display: flex ;
  justify-content: center;
  align-items: center;
}

.banner-container .container .right .teacher-image {
  width: 100%;
  margin: 0 auto;
}
.banner-container .container .right .teacher-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* mobile */

@media(max-width:767px) {
  .banner-container .container {
    flex-direction: column;
    height: unset;
    min-height: 100vh;
  }

  .banner-container .container .right,
  .banner-container .container .left {
    width: 100%;
  }

  .banner-container .container .left .btns button {
    width: 100%;
    margin: 0;
    margin-bottom: 1rem;
    padding: 0.5rem 0;
  }
}

/* dark */

body.dark .banner-container  {
  background:#262626;
  color: #fff
}
</style>