<template>
  <!-- Loading -->
  <LoadingComponent v-if="is_loading" />
  <!-- Error -->
  <ErrorComponent v-else-if="error && !is_loading" :error="error" />
  <!--  Data -->
  <template v-else>
    <div class="container">
      <div class="export-container">
        <button
          class="export-button"
          id="toEXCEL"
          @click="exportToExcel('bills_table')"
        >
          تحميل ملف Exel
        </button>
      </div>
      <div class="table" id="bills">
        <div class="table__body">
          <table id="bills_table">
            <thead>
              <tr>
                <th>التسلسل</th>
                <th>اجمالي سعر الفاتورة</th>
                <th>التخفيض</th>
                <th>الكوبون</th>
                <!-- <th>عدد المشتريات</th> -->
                <th>المشتريات</th>
                <th>حالة الدفع</th>
                <th>وقت الدفع</th>
                <th>طريقة الدفع</th>
                <th>رقم الفاتورة</th>
                <th>وقت إنشاء الفاتورة</th>
              </tr>
            </thead>
            <tbody>
              <!--  Data -->
              <tr v-for="(bill, index) in bills" :key="index">
                <td>{{ index + 1 }}</td>
                <td>
                  {{ bill?.price }}
                </td>
                <td>{{ bill?.promo_code__discount_percent }}%</td>
                <td>
                  {{
                    bill?.promo_code__code
                      ? bill?.promo_code__code
                      : "--لا يوجد كوبون--"
                  }}
                </td>
                <td>{{ bill?.course__name }}</td>
                <td class="status">
                  <span
                    :class="
                      bill?.pay_status === 'P'
                        ? 'pending'
                        : bill?.pay_status === 'E'
                        ? 'expired'
                        : bill?.pay_status === 'F'
                        ? 'unpaid'
                        : 'paid'
                    "
                  >
                    {{
                      bill?.pay_status === "P"
                        ? "في انتظار الدفع"
                        : bill?.pay_status === "E"
                        ? "انتهت الصلاحية"
                        : bill?.pay_status === "F"
                        ? "فشل الدفع"
                        : "تم الدفع"
                    }}
                  </span>
                </td>
                <td>{{ getHumanDate(bill?.expires_at) }}</td>
                <td>
                  {{
                    bill?.pay_method === "M"
                      ? "يدوي"
                      : bill?.pay_method === "C"
                      ? "عن طريق كود"
                      : "فوري"
                  }}
                </td>
                <td>
                  {{ bill?.sequence }}
                </td>
                <td>
                  {{ getHumanDate(bill?.created) }}
                </td>
              </tr>
              <!-- No Data -->
              <tr v-if="!is_loading && bills.length == 0">
                <td colspan="12" class="no-data">لا يوجد فواتير حتي الان</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <PaginationBtns :next="hav_next" :prev="hav_prev" :link="'bills'" />
    </div>
  </template>
</template>

<script>
import PaginationBtns from "@/components/PaginationBtns/PaginationBtns.vue";
import exportTableToExcel from "../../../services/csv";
import config from "@/services/config";
import axios from "axios";
import LoadingComponent from "@/components/Loading/LoadingComponent.vue";
import ErrorComponent from "@/components/Error/ErrorComponent.vue";
import { watchEffect } from "vue";

export default {
  name: "BillsComponentComponent",
  components: {
    PaginationBtns,
    LoadingComponent,
    ErrorComponent,
  },
  data() {
    return {
      bills: [],
      is_loading: true,
      error: null,
      hav_next: false,
      hav_prev: false,
    };
  },
  methods: {
    exportToExcel(id) {
      exportTableToExcel(id, "الفواتير");
    },
    getBills() {
      axios
        .get(`${config.baseURL}student/student-invoice-list`, {
          headers: {
            auth: this.$store.state.userToken,
          },
        })
        .then((res) => {
          this.bills = res.data.results;
          this.hav_next = res.data.next ? true : false;
          this.hav_prev = res.data.previous ? true : false;
        })
        .catch((err) => {
          this.error = err?.response?.status
            ? err.response.status
            : err.message;
          console.log(err.message);
        })
        .finally(() => {
          this.is_loading = false;
        });
    },
    getHumanDate(d) {
      const date = new Date(d);
      const options = {
        weekday: "long",
        year: "numeric",
        month: "short",
        day: "numeric",
      };
      return date.toLocaleDateString("ar", options);
    },
  },
  created() {
    watchEffect(() => {
      this.getBills();
    });
  },
};
</script>

<style scoped>
.export-container {
  text-align: center;
  margin-bottom: 2rem;
}

.export-button {
  background-color: #fff;
  color: #000;
  box-shadow: 0px 0px 2px 0px #000000;
  padding: 1rem 2rem;
  border-radius: 30px;
  cursor: pointer;
  border: none;
  outline: none;
  font-size: 1rem;
  transition: all 0.3s;
}

.export-button:hover {
  background-color: #038c4c;
  color: #fff;
}

.table > :not(caption) > * > * {
  background-color: transparent;
}

.table {
  position: relative;
  width: 100%;
  height: 75vh;
  background-color: #fff;
  box-shadow: 0 0.4rem 0.8rem #0005;
  border-radius: 1.25rem;
  overflow: hidden;
  padding: 2rem;
  direction: ltr;
}

.table__body {
  background-color: #fff;
  width: 100%;
  height: 100%;
  border-radius: 1.25rem;
  padding: 0 2rem 2rem 2rem;
  overflow: auto;
  overflow: overlay;
}

.table__body::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

.table__body::-webkit-scrollbar-thumb {
  border-radius: 0.5rem;
  background-color: #c3c3c3;
  border: 1px solid #c3c3c3;
}

.table__body::-webkit-scrollbar-track {
  background-color: #eeeeee;
}

table {
  width: 100%;
  direction: rtl;
}

table,
th,
td {
  border-collapse: collapse;
  padding: 1rem 0.5rem;
  text-align: center;
  min-width: 190px;
  min-height: 31px;
}

thead th {
  position: sticky;
  top: 0;
  /* left: 0; */
  z-index: 1;
  background-color: #fff;
  color: #000;
  border: none;
  border-bottom: 0.1rem solid #00000020;
}

tbody tr {
  background-color: #fff;
  border: none;
  border-bottom: 0.1rem solid #00000010;
}

th {
  color: #000;
  font-weight: 700;
}

td {
  color: #000000;
  font-weight: 500;
}

.expired,
.pending,
.unpaid,
.paid,
.pending {
  padding: 0.5rem 2rem;
  border-radius: 2rem;
  width: 100%;
  display: inline-block;
  color: #fff !important;
}

.paid {
  background-color: #2abf7a;
}

.expired,
.unpaid {
  background-color: #e71c39;
}

.pending {
  background-color: #f9a825;
}
.no-data {
  color: #000 !important;
  font-size: 1.5rem !important;
  font-weight: 500 !important;
}
body.dark .no-data {
  color: #fff !important;
}

/* Dark Mode */
body.dark th {
  color: #fff;
}

body.dark .export-button {
  background-color: #1d4955;
  color: #fff;
}

body.dark .export-button:hover {
  background-color: #038c4c;
}

body.dark .table__body::-webkit-scrollbar-thumb {
  background-color: #235562;
  border: 1px solid #13323a;
}

body.dark .table__body::-webkit-scrollbar-track {
  background-color: #13323a;
}

body.dark tbody tr,
body.dark .table__body,
body.dark .table {
  background-color: #1d4955;
}

body.dark thead th {
  background-color: #1d4955;
  color: #fff;
  border: none;
  border-bottom: 0.1rem solid #ffffff20;
}

body.dark td {
  color: #ffffff;
}

body.dark .pending,
body.dark .expired,
body.dark .unpaid,
body.dark .paid {
  color: #1d4955 !important;
}

body.dark .paid {
  background-color: #31d287;
}

body.dark .expired,
body.dark .unpaid {
  background-color: #e9334e;
}

body.dark .pending {
  background-color: #f9a825;
}

/*  Media queries */
@media screen and (max-width: 992px) {
  .export-button {
    padding: 0.75rem 1.5rem;
    font-size: 0.9rem;
  }

  .table {
    padding: 1rem;
  }

  .table__body {
    padding: 0 1rem 1rem 1rem;
  }

  th,
  td {
    font-size: 0.9rem;
  }
}
</style>
