<template>
  <div class="loading">
    جاري التحميل
    <span
      class="spinner-border mt-2"
      style="width: 3rem !important; height: 3rem !important"
    ></span>
  </div>
</template>
<script>
export default {
  name: "LoadingComponent",
};
</script>
<style scoped>
.loading {
  width: 100%;
  padding: 1rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #000;
  font-size: 1.5rem;
  font-weight: 700;
}
body.dark .loading {
  color: #fff;
}
</style>
