import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";
import Cookies from "js-cookie";
import HomeView from "@/views/HomeView.vue";
import ProfileLayout from "@/views/UserProfile/ProfileLayout.vue";
import UserProfileView from "@/views/UserProfile/UserProfileView.vue";
import SecurityLoginHistoryView from "@/views/UserProfile/SecurityLoginHistoryView.vue";
import CoursesView from "@/views/UserProfile/CoursesView.vue";
import CourseContentView from "@/views/UserProfile/CourseContentView.vue";
import ViewsDetailsView from "@/views/UserProfile/ViewsDetailsView.vue";
import BillsView from "@/views/UserProfile/BillsView.vue";
import SubscriptionsView from "@/views/UserProfile/SubscriptionsView.vue";
import ExamsResultsView from "@/views/UserProfile/ExamsResultsView.vue";
// import ExamAnswersView from "@/views/UserProfile/ExamAnswersView.vue";
import AssignmentResultsView from "@/views/UserProfile/AssignmentResultsView.vue";
import VideoQuizResultsView from "@/views/UserProfile/VideoQuizResultsView.vue";
import NotificationsView from "@/views/UserProfile/NotificationsView.vue";
import NewsView from "@/views/UserProfile/NewsView.vue";
import LevelIndicatorView from "@/views/UserProfile/LevelIndicatorView.vue";
import ExamStudentAnswersView from "@/views/UserProfile/ExamStudentAnswersView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: { title: " الرئيسيه", requiresAuth: false },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/LoginView.vue"),
    meta: { title: "تسجيل الدخول", requiresAuth: false },
  },
  {
    path: "/register",
    name: "register",
    component: () => import("@/views/RegisterView.vue"),
    meta: { title: "حساب جديد", requiresAuth: false },
  },
  {
    path: "/forget-password",
    name: "forget-password",
    component: () => import("@/views/ForgetPasswordView.vue"),
    meta: { title: "نسيت كلمة المرور", requiresAuth: false },
  },
  {
    path: "/courses",
    name: "coursesListout",
    component: () => import("../views/Courses/CoursesList.vue"),
    meta: { title: "جميع الكورسات", requiresAuth: false, },
  },
  {
    path: "/profile",
    name: "profile",
    component: <ProfileLayout />,
    children: [
      {
        path: "",
        name: "user_profile",
        component: <UserProfileView />,
        meta: {
          title: " الملف الشخصي ",
          requiresAuth: true
        },
      },
      {
        path: "/user-profile/my-courses",
        name: "user_courses",
        component: <CoursesView />,
        meta: {
          title: "كورساتي",
          requiresAuth: true,
        },
      },
      {
        path: "/user-profile/courses",
        name: "coursesList",
        component: () => import("../views/Courses/CoursesList.vue"),
        meta: { title: "جميع الكورسات", requiresAuth: true, },
      },
      {
        path: "/user-profile/course-content/:id",
        name: "course_content",
        props: true,
        component: <CourseContentView />,
        meta: {
          title: "محتوي الكورس",
          requiresAuth: true,
        },
      },
      {
        path: "/lesson/:course_id/:lesson_id",
        name: "lesson",
        props: true,
        component: () => import('../views/UserProfile/LessonView.vue'),
        meta: {
          title: "الدرس",
          requiresAuth: true,
        },
      },
      {
        path: "/user-profile/history",
        name: "user_history",
        component: <SecurityLoginHistoryView />,
        meta: {
          title: "الآمان و تاريخ تسجيل الدخول",
          requiresAuth: true,
        },
      },
      {
        path: "/user-profile/details-views",
        name: "details_views",
        component: <ViewsDetailsView />,
        meta: {
          title: "تفاصيل المشاهدة",
          requiresAuth: true,
        },
      },
      {
        path: "/user-profile/bills",
        name: "bills",
        component: <BillsView />,
        meta: {
          title: "الفواتير",
          requiresAuth: true,
        },
      },
      {
        path: "/user-profile/subscriptions",
        name: "subscriptions",
        component: <SubscriptionsView />,
        meta: {
          title: "الاشتراكات",
          requiresAuth: true,
        },
      },
      {
        path: "/user-profile/exams-results",
        name: "exams_results",
        component: <ExamsResultsView />,
        meta: {
          title: "نتائج الامتحانات",
          requiresAuth: true,
        },
      }, {
        path: "/user-profile/exam-answers/:id",
        name: "exam_answers",
        component: <ExamStudentAnswersView />,
        meta: {
          title: "اجابات الامتحان",
          requiresAuth: true,
        },
        beforeEnter: (to, from, next) => {
          const examTitle = to.query.exam;
          to.meta.title = examTitle;
          document.title = examTitle;
          next();
        },
      },
      {
        path: "/user-profile/assignment-results",
        name: "assignment_results",
        component: <AssignmentResultsView />,
        meta: {
          title: "نتائج الواجبات",
          requiresAuth: true,
        },
      },
      {
        path: "/user-profile/video-quiz-results",
        name: "video_quiz_results",
        component: <VideoQuizResultsView />,
        meta: {
          title: "نتائج كويز الفيديو",
          requiresAuth: true
        },
      },
      {
        path: "/user-profile/notifications",
        name: "notifications",
        component: <NotificationsView />,
        meta: {
          title: "الاشعارات",
          requiresAuth: true
        },
      },
      {
        path: "/user-profile/news",
        name: "news",
        component: <NewsView />,
        meta: {
          title: "الاشعارات",
          requiresAuth: true
        },
      },
      {
        path: "/user-profile/level-indicator",
        name: "level_indicator",
        component: <LevelIndicatorView />,
        meta: {
          title: "مؤشر المستوى"
          , requiresAuth: true
        },
      },
    ],
    meta: { title: "حسابي" },
  },
  {
    path: "/start-exam/:exam_id/:exam_name",
    name: "startExam",
    props: true,
    component: () => import("../views/Exam/StartExamView.vue"),
    meta: {
      title: "الامتحان",
      requiresAuth: true,
    },
  },
  {
    path: "/exam-questions/:exam_id",
    props: true,
    name: "examQuestions",
    component: () => import("../views/Exam/ExamQuestionsView.vue"),
    meta: {
      title: "الامتحان",
      requiresAuth: true,
      enterClass: "animate__animated animate__fadeInLeft",
      leaveClass: "animate__animated animate__fadeOutRight",
    },
  },
  {
    path: "/end-exam/success",
    name: "endExamSuccess",
    component: () => import("../views/Exam/EndExamSuccessView.vue"),
    meta: {
      title: "الامتحان",
      requiresAuth: true,
    },
  },
  {
    path: "/end-exam/failed",
    name: "endExamFailed",
    component: () => import("../views/Exam/EndExamFailedView.vue"),
    meta: {
      title: "الامتحان",
      requiresAuth: true,
    },
  },
  {
    path: "/cart",
    name: "cart",
    // component: () => import("@/views/UserProfile/ProfileLayout.vue"),
    meta: { title: "السله", requiresAuth: true },
  },
  {
    path: "/courses/:phase",
    name: "courseList",
    component: () => import("../views/Courses/CoursesList.vue"),
    meta: { title: "الكورسات", requiresAuth: false },
  },
  {
    path: "/payment/:id",
    name: "payment",
    props: true,
    component: () => import("../views/PaymentConfirmView.vue"),
    meta: { title: "الدفع", requiresAuth: true },
  },

  {
    path: "/:pathMatch(.*)*",
    name: "notfound",
    component: () => import("@/views/NotFound.vue"),
    meta: {
      title: "صفحه غير موجوده",
      requiresAuth: false,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});


router.beforeEach((to, from, next) => {
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  // to handle if profile open from dashboard
  // Get the full URL of the current page
  const urlParams = new URLSearchParams(window.location.search);
  const dashboard_token = urlParams.get('token')

  if (dashboard_token) {

    Cookies.set("user_token", dashboard_token)
    Cookies.remove("user_data")
    store.commit("LOGIN_USER", dashboard_token);

  } else {
    // handle reload for login
    const token = Cookies.get("user_token")
    if (token) {
      store.commit("LOGIN_USER", token);
    }
  }

  const isAuthenticated = store.state.isAuthenticated;

  // Set document title from route meta, default to 'App' if no title is set
  document.title = to.meta.title || "not found";

  // if user try to access login and register routes and user is auth
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (isAuthenticated) {
      next();
    } else {
      if (to.name === 'course_content') {
        next()
      } else {
        next({ name: "login" });
      }
    }
  } else {
    if (isAuthenticated) {
      next({ name: "user_profile" });
    } else {
      next();
    }
  }
});



// router.beforeEach((to, from, next) => {
//   // handle reload for login
//   const token = Cookies.get("user_token");
//   if (token) {
//     store.commit("LOGIN_USER", token);
//   }

//   const isAuthenticated = store.state.isAuthenticated;

//   // Set document title from route meta, default to 'App' if no title is set
//   document.title = to.meta.title || "not found";

//   // if user try to access login and register routes and user is auth
//   if (to.matched.some((record) => record.meta.requiresAuth)) {
//     if (isAuthenticated) {
//       next();
//     } else {
//       next({ name: "login" });
//     }
//   } else {
//     if (isAuthenticated) {
//       next({ name: "user_profile" });
//     } else {
//       next();
//     }
//   }
// });
// router.beforeEach((to, from, next) => {
//   document.body.scrollTop = 0; // For Safari
//   document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
//   const token = Cookies.get("user_token");
//   if (token) {
//     store.commit("LOGIN_USER", token);
//   }

//   const isAuthenticated = store.state.isAuthenticated;

//   document.title = to.meta.title || "not found";

//   if (to.name === "coursesListout") {
//     next();
//   } else if (to.matched.some((record) => record.meta.requiresAuth)) {
//     if (isAuthenticated) {
//       next();
//     } else {
//       next({ name: "login" });
//     }
//   } else {
//     if (isAuthenticated) {
//       next({ name: "user_profile" });
//     } else {
//       next();
//     }
//   }
// });

export default router;
