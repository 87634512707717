

 <template>
  <div
    class="father-container w-100 d-flex flex-column justify-content-around align-items-center"
    :class="{ 'dark-mode': darkModeEnabled }"
  >
    <div class="con" :class="{ 'dark-mode': darkModeEnabled }">
      <div class="title">
        <p>
          <img src="@/assets/MGAssets/bill.png" alt="bell" />

          أخر الاخبار
        </p>
      </div>

      <div class="news-ticker">
        <div class="ticker-wrapper">
          <div
            class="card"
            :class="{ 'dark-mode': darkModeEnabled }"
            v-for="(item, index) in [...cards, ...cards]"
            :key="index"
          >
            <div class="ins">
              <img class="card-image" :src="item.ImgSrc" alt="Card Image" />
              <p>{{ item.content }}</p>
            </div>
          </div>
        </div>
      </div>
      <p v-if="cards.length === 0">لا توجد أخبار متاحة</p>
    </div>
    <button @click="scrollToTop">
      <img src="@/assets/MGAssets/arrow.png" alt="arrow" />
    </button>
  </div>
</template>


<script>
import axios from "axios";
import config from "@/services/config";

export default {
  name: "SpecialWatermelonComponent",
  data() {
    return {
      cards: [],
      loading: true,
      error: null,
    };
  },
  methods: {
    async fetchNews() {
      try {
        const response = await axios.get(`${config.baseURL}extra/news-list`, {
          headers: {
            auth: this.$store.state.userToken,
          },
        });

        console.log("API Response:", response); // Check the entire response
        console.log("Results Data:", response.data.results); // Check the results array

        // Now access the results array and map the data
        this.cards = response.data.results.map((item) => ({
          ImgSrc: item.image_url || require("@/assets/MGAssets/MGprof.png"), // Default image if none provided
          content: item.text, // The 'text' field in the response
        }));
      } catch (err) {
        console.error("Fetch error:", err);
        this.error = "حدث خطأ أثناء جلب الأخبار.";
      } finally {
        this.loading = false;
      }
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
  computed: {
    darkModeEnabled() {
      return this.$store.getters.isDarkModeEnabled;
    },
  },
  mounted() {
    this.fetchNews();
  },
};
</script>

<style scoped>
.con {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 78px;
  width: 100%;
  height: 240px;
  margin-top: 10%;
}

.dark-mode.con p {
  color: white;
}
.title {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 10px;

  width: 99px;
  height: 30px;

  flex: none;
  order: 0;
  flex-grow: 0;
}
.title img {
  width: 18px;
  height: 18px;
}
.title p {
  width: 100px;
  height: 30px;

  font-family: "Cairo";
  font-style: normal;
  font-weight: 600;
  font-size: 18.6907px;
  line-height: 163%;
  text-align: center;

  color: #000000;
}

.news-ticker {
  overflow: hidden;
  width: 100vw;
}

.ticker-wrapper {
  display: flex;
  animation: scroll 60s linear infinite;
}

.news-ticker:hover .ticker-wrapper {
  animation-play-state: paused;
}

@keyframes scroll {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(100%);
  }
}

.card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 23px 26px;
  gap: 10px;

  width: 436px;
  height: 132px;

  background: #ffffff;
  border-radius: 35px;

  flex: none;
  order: 4;
  flex-grow: 0;
  margin-right: 1%;
}
.dark-mode.card {
  background: #262626;
}
.card .ins {
  width: 383px;
  height: 85.93px;

  flex: none;
  order: 0;
  flex-grow: 0;
}
.ins img {
  box-sizing: border-box;

  position: absolute;
  width: 52px;
  height: 52px;
  left: 357px;
  top: 23px;
}
.ins p {
  /* هنتعرف على العناصر الانتقالية واستخدامات عناصر السلسة الانتقالية الاولى وكمان حالات تأكسدها وخواصها وكمان هنتكلم عن الحديد وتحضيره واهم مركباته .... */

  position: absolute;
  width: 308.94px;
  height: 71.93px;
  left: 26px;
  top: 37px;

  font-family: "Cairo";
  font-style: normal;
  font-weight: 500;
  font-size: 12.971px;
  line-height: 163%;
  /* or 21px */
  text-align: right;

  color: rgba(0, 0, 0, 0.7);
}

.father-container button {
  /* Property 1=Frame 96 */
  margin: 5%;
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px;
  gap: 10px;

  /* position: absolute; */
  width: 55px;
  height: 55px;
  /* left: calc(50% - 55px / 2 + 0.5px); */
  /* top: 3442px; */
  border: 1px solid transparent;
  background: #f58437;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
  border-radius: 27.5px;
  /* margin-bottom: 20px; */
}
/* @keyframes scroll {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(100%);
  }
} */
</style> 
