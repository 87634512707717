<template>
  <div class="progress-bar-container">
    <div id="line" class="progress-line"></div>
  </div>
</template>
<script>
export default {
  name: "ProgressBarComponent",
  mounted() {
    document.addEventListener("scroll", () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const scrollHeight =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;
      const scrollPercent = (scrollTop / scrollHeight) * 100;
      document.getElementById("line").style.width = `${scrollPercent}%`;
    });
  },
};
</script>
<style scoped>
.progress-bar-container {
  width: 100%;
  height: 5px;
  background-color: #00000080;
  position: absolute;
  bottom: 0;
  left: 0;
  direction: ltr;
}

.progress-bar-container .progress-line {
  height: 100%;
  width: 0;
  background-color: #f1c40f;
  transition: width ease-in-out;
}
</style>
