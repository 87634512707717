<template>
    <div ref="player"
        style="position: absolute;top: 0;left: 0;width: 100%;height: 100%;opacity: 1;transition: opacity 0.5s;"> </div>
</template>
<script>
import YouTubePlayer from "youtube-iframe";

export default {
    name: 'YoutubePromoComponent',
    props: ['video_id', 'is_hover'],
    data() {
        return {
            player: null,
            ready: false
        }
    },
    mounted() {
        // Initialize the YouTube player
        YouTubePlayer.load((YT) => {
            this.player = new YT.Player(this.$refs.player, {
                videoId: "dRmcn0fJZDE",
                playerVars: {
                    playlist: "dRmcn0fJZDE",
                    loop: 1,
                    autoplay: 1,
                    controls: 0,
                    rel: 0,
                    modestbranding: 1,
                    mute: 1, // Mute video by default for autoplay
                },
                events: {
                    onReady: this.onPlayerReady,
                    onStateChange: this.onPlayerStateChange,
                },
            });
        });
    },
    methods: {
        onPlayerReady() {
            console.log('YouTube Player is ready');
        },
        onPlayerStateChange(event) {
            console.log("Player state changed", event);
        }
    },
}
</script>
<style scoped></style>