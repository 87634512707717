<template>
  <div class="container">
    <div class="courses-statistics-box-container">
      <template v-if="!is_loading">
        <!-- lessons -->
        <div class="courses-statistics-box-item">
          <h3 class="title">عدد الفيديوهات اللي شوفتها</h3>
          <div class="statistics-prog-container">
            <span
              class="statistics-prog"
              :style="{
                width: analysis_data?.progress_in_all_courses?.progress + '%',
              }"
            ></span>
          </div>
          <div class="bottom justify-content-between">
            <span class="video">
              <FaVideo />
              {{
                analysis_data?.progress_in_all_courses?.watched_lessons
              }}
              فيديو من
              {{ analysis_data?.progress_in_all_courses?.total_lessons }}
            </span>
            <span class="average">
              {{ analysis_data?.progress_in_all_courses?.progress }} %
            </span>
          </div>
        </div>
        <!-- exams -->
        <div class="courses-statistics-box-item">
          <h3 class="title">عدد الاختبارات اللي خلصتها</h3>
          <div class="statistics-prog-container">
            <span
              class="statistics-prog"
              :style="{ width: analysis_data?.exam_progress?.progress + '%' }"
            ></span>
          </div>
          <div class="bottom justify-content-between">
            <span class="test">
              <PhFillTestTube />
              {{ analysis_data?.exam_progress?.total_exams_taken }} اختبار من
              {{ analysis_data?.exam_progress?.total_exams_should_be_taken }}
            </span>
            <span class="average">
              {{ analysis_data?.exam_progress?.progress }} %
            </span>
          </div>
        </div>

        <!-- average results -->
        <div class="courses-statistics-box-item">
          <h3 class="title">متوسط النتائج اللي جبتها</h3>
          <div class="statistics-prog-container">
            <span
              class="statistics-prog"
              :style="{ width: analysis_data?.exam_progress?.average + '%' }"
            ></span>
          </div>
          <div class="bottom justify-content-end">
            <!-- <span class="average">
            {{ analysis_data?.exam_progress?.average }}
            </span> -->
            <span class="average">
              {{ analysis_data?.exam_progress?.average }} %
            </span>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import config from "@/services/config";
import { FaVideo, PhFillTestTube } from "@kalimahapps/vue-icons";
import axios from "axios";
export default {
  name: "CoursesStatisticsComponent",
  components: {
    FaVideo,
    PhFillTestTube,
  },
  data() {
    return {
      is_loading: true,
      analysis_data: {},
    };
  },
  methods: {
    get_data() {
      axios
        .get(`${config.baseURL}analysis/profile-analysis`, {
          headers: {
            auth: this.$store.state.userToken,
          },
        })
        .then((res) => {
          // courses progress
          res.data.progress_in_all_courses.progress =
            res.data.progress_in_all_courses.total_lessons == 0
              ? 0
              : Math.floor(
                  (res.data.progress_in_all_courses.watched_lessons /
                    res.data.progress_in_all_courses.total_lessons) *
                    100
                );

          // exams progress
          res.data.exam_progress.progress =
            res.data.exam_progress.total_exams_should_be_taken == 0
              ? 0
              : Math.floor(
                  (res.data.exam_progress.total_exams_taken /
                    res.data.exam_progress.total_exams_should_be_taken) *
                    100
                );

          // average
          res.data.exam_progress.average =
            res.data.exam_progress.total_exam_scores == 0
              ? 0
              : Math.floor(
                  (res.data.exam_progress.total_student_scores /
                    res.data.exam_progress.total_exam_scores) *
                    100
                );

          this.analysis_data = res.data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.is_loading = false;
        });
    },
  },
  created() {
    this.get_data();
  },
};
</script>

<style scoped>
.courses-statistics-box-container {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 50px;
  margin: 0 auto;
  box-shadow: 0 0 10px 0 #00000010;
  gap: 2rem;
  overflow: hidden;
}

.statistics-prog-container {
  position: relative;
  background-color: #d9d9d9;
  border-radius: 23px;
  width: 100%;
  height: 11px;
  overflow: hidden;
}

.statistics-prog {
  position: relative;
  display: block;
  width: 90%;
  height: 11px;
  border-radius: 23px;
  background-color: #2abf7a;
  opacity: 0;
  animation: progress 1.5s ease-in-out forwards;
}

/* Animation to show the progress bar */
@keyframes progress {
  0% {
    opacity: 0;
    width: 0;
  }

  100% {
    opacity: 1;
  }
}

.courses-statistics-box-item {
  width: 100%;
}

.title {
  font-size: 1.25rem;
  font-weight: 500;
  color: #000;
  margin-bottom: 1rem;
}

.bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  margin-top: 0.5rem;
}

.video,
.test,
.average {
  font-size: 13px;
  font-weight: 500;
  color: #000;
}

.video svg,
.test svg {
  font-size: 1rem;
}

.video svg {
  color: #e71c39;
}

.test svg {
  color: #9747ff;
}

.average {
  background-color: #000;
  color: #fff;
  text-align: center;
  width: 38px;
  height: 17px;
  border-radius: 20px;
}

/* Dark Mode */
body.dark .courses-statistics-box-container {
  background-color: #1d4955;
}

body.dark .average,
body.dark .statistics-prog-container {
  background-color: #13323a;
}

body.dark .title,
body.dark .bottom span {
  color: #fff;
}

/*  Media queries */
@media screen and (max-width: 992px) {
  .courses-statistics-box-container {
    width: 100%;
  }

  .title {
    font-size: 1rem;
  }
}
</style>
