<template>
    <div class="dark-mode-btn" @click="toggleDarkMode" :class="{ active: darkMode }">
        <span class="dark">
            <PhMoonStars class="moon-icon" />
        </span>
        <span class="light">
            <LuSun class="sun-icon" />
        </span>
    </div>
</template>
<script>
import {
    LuSun,
    PhMoonStars,
} from "@kalimahapps/vue-icons";
export default {
    props: ['toggle-dark'],
    mounted() {
        let dark_mode_val = localStorage.getItem('darkMode')
        if (dark_mode_val) {
            if (dark_mode_val == 'dark') {
                this.darkMode = true
                this.$store.commit("SET_DARK_MODE", true);
                document.body.classList.toggle("dark");
            }else {
                this.darkMode = false
                this.$store.commit("SET_DARK_MODE", false);
            }
        }else {
            localStorage.setItem('darkMode', 'dark')
        }
    },
    data() {
        return {
            darkMode: false
        }
    },
    components: {
        LuSun,
        PhMoonStars,
    },
    methods: {
        toggleDarkMode() {
            let dark_mode_val = localStorage.getItem('darkMode')
            this.darkMode = !this.darkMode;
            document.body.classList.toggle("dark");
            this.$store.commit("SET_DARK_MODE", this.darkMode);
            localStorage.setItem("darkMode", dark_mode_val == 'dark' ? 'light' : 'dark');
        },
    }
}
</script>
<style scoped>
/* dark mode btn */
.dark-mode-btn {
    background-color: #1e1e1e;
    cursor: pointer;
    border-radius: 1rem;
    width: 60px;
    height: 30px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
}

body.dark .dark-mode-btn {
    background-color: #000;
}

.dark-mode-btn::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 3%;
    transform: translate(3%, -50%);
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    background-color: #fff;
    transition: 0.3s;
    z-index: 0;
}

.dark-mode-btn.active::before {
    left: 97%;
    transform: translate(-103%, -50%);
}

.dark-mode-btn span {
    position: relative;
    z-index: 1;
}

.dark-mode-btn span.dark {
    right: 6%;
}

.dark-mode-btn span.light {
    left: 6%;
}

.dark-mode-btn .moon-icon,
.dark-mode-btn .sun-icon {
    font-size: 1.25em;
}

.dark-mode-btn .dark .moon-icon {
    fill: #2abf7a;
}

.dark-mode-btn .light .sun-icon {
    color: #000000;
}

.dark-mode-btn.active .light .sun-icon {
    color: #ffffff;
}
</style>