<template>
  <footer
    :class="
      darkModeEnabled
        ? 'bg-custom text-white text-center'
        : 'bg-white text-center'
    "
  >
    <!-- Grid container -->
    <div class="container p-4 pb-0">
      <!-- Section: Social media -->
      <section class="mb-4 social-media">
        <!-- Facebook -->
        <a
          class="btn text-white btn-floating m-1 border-0"
          :class="darkModeEnabled ? 'bg-blue-600' : 'bg-[#3b5998]'"
          style="background-color: #3b5998"
          href="https://www.facebook.com/Dr.Mahmoud.ElGharabawy"
          role="button"
        >
          <DeFacebookOriginal class="icon" />
        </a>

        <!-- TikTok -->
        <a
          class="btn text-white btn-floating m-1 border-0"
          :class="darkModeEnabled ? 'bg-black' : 'bg-[#010101]'"
          style="background-color: #010101"
          href="https://www.tiktok.com/@mahmoud.elgharaba1?_t=8irZWjFn0FR&_r=1"
          role="button"
        >
          <AkTiktokFill class="icon" />
        </a>

        <!-- YouTube -->
        <a
          class="btn text-white btn-floating m-1 border-0"
          :class="darkModeEnabled ? 'bg-red-600' : 'bg-[#FF0000]'"
          style="background-color: #ff0000"
          href="https://www.youtube.com/@mahmoudelgharabawy4195"
          role="button"
        >
          <PhYoutubeLogo class="icon" />
        </a>

        <!-- Instagram -->
        <a
          class="btn text-white btn-floating m-1 border-0"
          :class="darkModeEnabled ? 'bg-black' : 'bg-[#010101]'"
          style="background: linear-gradient(45deg, #e60d0d, #ffa6a6)"
          href="https://www.instagram.com/dr.mahmoud.elgharabawy?igsh=MWt1Y2swbW0xMnpjZw=="
          role="button"
        >
          <AkInstagramFill class="icon" />
        </a>
      </section>
      <!-- Section: Social media -->
    </div>
    <!-- Grid container -->

    <!-- Copyright -->
    <div
      class="text-center p-3"
      :class="darkModeEnabled ? 'bg-dark-copy' : 'bg-light-level'"
    >
      حقوق النشر © {{ year }} لشركة Easy Tech . كل الحقوق محفوظة.
      <img
        v-if="!darkModeEnabled"
        src="@/assets/MGAssets/easy.png"
        alt="Easy Tech Logo"
      />
      <img v-else src="@/assets/MGAssets/w-easy.png" alt="Easy Tech Logo" />
    </div>
    <!-- Copyright -->
  </footer>
</template>

<script>
import {
  PhYoutubeLogo,
  AkTiktokFill,
  DeFacebookOriginal,
  AkInstagramFill,
} from "@kalimahapps/vue-icons";
export default {
  name: "FooterComponent",
  components: {
    PhYoutubeLogo,
    AkTiktokFill,
    DeFacebookOriginal,
    AkInstagramFill,
  },
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
  computed: {
    darkModeEnabled() {
      return this.$store.getters.isDarkModeEnabled;
    },
  },
};
</script>
<style scoped>
.bg-dark-copy {
  background-color: #363535 !important;
  /* Darker background */
}

.bg-custom {
  background-color: #262626 !important;
  /* Replace with your custom color */
}

.bg-light-level {
  background-color: #f8f9fa;
  /* Lighter background */
}

.icon {
  font-size: 1.5em;
}
.social-media {
  display: flex;
  justify-content: center;
  align-items: center;
}
.social-media a {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
<!-- <style scoped>
.right-content {
  width: 350px;
  height: 40px;
}
.middle-content {
  width: 450px;
  height: 40px;
}

.left-content {
  width: 450px;
  height: 40px;
}

span {
  width: 1px;
  min-height: 20px;
}

.con {
  background: #ffffff;
}

.footer {
  width: 100%;
  padding: 5px 0;
}

.whatsapp {
  font-weight: 600;
  direction: ltr;

  font-family: "Cairo", sans-serif;
  font-size: 1.25vw;
  line-height: 20px;
  color: #000000;
  margin-top: 3%;
  min-height: 20px;
}

.icon {
  width: 19.6px;
  height: 19.6px;
  font-size: 19.6px; /* Ensures the icon fits within the box */
  transition: transform 0.3s ease, color 0.3s ease;
  cursor: pointer;
}

.icon:hover {
  transform: scale(1.2); /* Slight enlargement on hover */
}

.facebook {
  color: #1877f2;
}

.instagram {
  color: #e1306c;
}

.linkedin {
  color: #0077b5;
}

.twitter {
  color: #1da1f2;
}

.youtube {
  color: #ff0000;
}

p {
  font-family: "Cairo", sans-serif;
  font-weight: 400;
  font-size: 0.9vw;
  line-height: 20px;
  color: #000000;
  margin-top: 3%;
  min-height: 20px;
}

.left-content p {
  margin: 0;
}

@media (max-width: 1000px) {
  .right-content {
    width: 350px;
    height: auto;
  }

  .middle-content {
    width: 450px;
    height: auto;
  }

  .left-content {
    width: 450px;
    height: auto;
  }

  p {
    font-size: 10px;
  }
}

@media (max-width: 576px) {
  .right-content {
    width: 350px;
    height: auto;
  }

  .middle-content {
    width: 412px;
    height: auto;
  }

  .left-content {
    width: 412px;
    height: auto;
  }
  p {
    font-size: 12px;
  }
  .whatsapp {
    font-size: 20px;
  }
}

@media (max-width: 767px) {
  .right-content {
    width: 100%;
    height: auto;
    padding: 0 1rem;
  }
  .middle-content {
    width: 100%;
    height: auto;
  }

  .left-content {
    width: 100%;
    height: auto;
  }
}

.con.dark-mode {
  background-color: #1e1e1e;
}
.con.dark-mode p {
  color: white;
}

.con.dark-mode span {
  color: white;
}
</style> -->
