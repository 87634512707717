<template>
  <!-- <div class="d-flex justify-content-center align-items-center w0" > -->
  <div class="container">
    <div class="row w-100" style="margin: 0 auto">
      <div
        class="feature-banner-1 d-flex flex-row flex-wrap justify-content-around align-items-start align-items-sm-center"
      >
        <!-- Image Section -->
        <div class="feature-banner-2 flex-grow-1 order-md-1 text-center">
          <img
            src="@/assets/MGAssets/imgMG2.png"
            alt="محمود غرباوي"
            class="feature-image img-fluid"
          />
        </div>

        <!-- Register Section -->
        <div
          class="feature-register d-flex flex-column justify-content-center align-items-center flex-grow-1 order-md-2 text-center"
        >
          <p class="h2">أشترك معانا</p>
          <p class="h3">و انضم لاكتر من 1543+ طالب</p>
          <img
            src="@/assets/MGAssets/allimg.png"
            alt="followers"
            class="fe-img img-fluid"
          />
        </div>
      </div>
    </div>
    <!-- Second Row with Cards -->

    <!-- <div class="row w-100 custom-margin">
      <div class="feature-banner-bottom row g-4 custom-margin">
        <div
          class="col-12 col-sm-6 col-md-4 d-flex align-items-start justify-content-center custom-margin"
          v-for="(card, index) in cards"
          :key="index"
        >
          <div class="card w-100" :class="{ 'dark-mode': darkModeEnabled }">
            <div class="inside">
              <div class="up">
                <h2>{{ card.title }}</h2>
                <p>{{ card.subtitle }}</p>
              </div>
              <p class="mid">{{ card.description }}</p>
              <div class="down">
                <router-link class="btn">المزيد</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
  <!-- </div> -->
</template>

<script>
export default {
  name: "FeatureComponent",
  data() {
    return {
      cards: [
        {
          title: "ارض جو",
          subtitle: "سنتر",
          description:
            "هنتعرف على العناصر الانتقالية واستخدامات عناصر السلسة الانتقالية الاولى وكمان حالات تأكسدها وخواصها وكمان هنتكلم عن الحديد وتحضيره واهم مركباته",
        },
        {
          title: "ارض جو",
          subtitle: "سنتر",
          description:
            "هنتعرف على العناصر الانتقالية واستخدامات عناصر السلسة الانتقالية الاولى وكمان حالات تأكسدها وخواصها وكمان هنتكلم عن الحديد وتحضيره واهم مركباته",
        },
        {
          title: "ارض جو",
          subtitle: "سنتر",
          description:
            "هنتعرف على العناصر الانتقالية واستخدامات عناصر السلسة الانتقالية الاولى وكمان حالات تأكسدها وخواصها وكمان هنتكلم عن الحديد وتحضيره واهم مركباته",
        },
      ],
    };
  },
  computed: {
    darkModeEnabled() {
      return this.$store.getters.isDarkModeEnabled;
    },
  },
};
</script>

<style scoped>
.container {
  /* gap: 26.25px; */
  width: 1178px;
  /* height: 450px; */
  margin-top: 100px;
  margin-bottom: 2rem;
}

.feature-banner-1 {
  /* position: relative
  ; */
  direction: ltr;
  width: 1178px;
  height: 129.21px;
  /* left: calc(50% - 1178px / 2); */
  /* top: 92.87px; */
  background: radial-gradient(
    29.18% 231.43% at 36.29% 53.13%,
    #ffffff 3.02%,
    #f58437 57.82%,
    #de4931 100%
  );
  border-radius: 36.3393px;
}

.feature-banner-2 {
  /* position: relative; */
  /* width: 828.74px; */
  /* height: 222.07px; */
  /* left: calc(50% - 828.74px / 2 - 0px); */
  /* top: 0px; */
}

.feature-image {
  position: relative;
  width: 546.1px;
  height: 222.07px;
  /* left: 10%; */
  /* top: 0px; */
  bottom: 90px;
}

.fe-img {
  /* position: relative;
  left: 30%;
  bottom: 10%; */
}
.feature-register {
  right: 50px;

  width: 162.52px;
  height: 101.92px;
  /* left: 80%; */
  /* top: 106.39px; */
  bottom: 50px;
  position: relative;
}
.feature-register .h2 {
  width: 162.52px;
  height: 38px;
  font-family: "Cairo";
  font-style: normal;
  font-weight: 700;
  font-size: 23.2168px;
  line-height: 163%;
  text-align: right;
  color: #ffffff;
}

.feature-register .h3 {
  width: 162.52px;
  height: 22px;
  font-family: "Cairo";
  font-style: normal;
  font-weight: 600;
  font-size: 13.5106px;
  line-height: 163%;
  text-align: center;
  color: #ffffff;
}
.feature-banner-bottom {
  position: relative;
  display: flex;
  /* flex-wrap: wrap; */
  /* gap: 20px; */
  /* top: 45%; */
}

.card {
  width: 100%;
  background: #fff;
  border-radius: 16px;
  padding: 20px;
}

.dark-mode.card {
  color: #ffffff;
  background: #262626;
}

.card .inside {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.card .inside .up {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card .inside h2 {
  font-size: 18px;
  font-weight: 600;
  color: #1e1e1e;
}

.card .inside p {
  font-size: 12px;
  font-weight: 600;
  color: rgba(30, 30, 30, 0.5);
}

.card .mid {
  font-size: 12px;
  text-align: center;
  color: rgba(0, 0, 0, 0.7);
}

.dark-mode.card .inside h2,
.dark-mode.card .inside p,
.dark-mode.card .mid {
  color: white;
}

.card .down .btn {
  background: #f58437;
  color: white;
  border-radius: 14px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 11.7918px;
  gap: 11.79px;
  box-sizing: border-box;
  width: 141.5px;
  height: 41.27px;
}

.card .down .btn:hover {
  background: #e6e6e6;
  color: black;
  box-sizing: border-box;
}
@media (max-width: 1000px) {
  .feature-banner-1 {
    width: 100%;
    height: auto;
  }
  .feature-image {
    /* position: relative; */
    width: 500px;
    height: 210px;
    bottom: auto;

    /* left: 10%; */
    /* top: 0px; */
  }
  .container {
    /* gap: 26.25px; */
    width: 100%;
    height: auto;
    margin-top: 100px;
  }

  .feature-register {
    bottom: auto;
    right: auto;
  }
}
@media (max-width: 768px) {
  .feature-banner-1 {
    width: 100%;
    height: auto;
    background: #df4a31;
  }
  .feature-register {
    bottom: auto;
    right: auto;
  }
  .feature-image {
    /* position: relative; */
    width: 446.1px;
    height: 200.07px;
    bottom: auto;

    /* left: 10%; */
    /* top: 0px; */
  }
  .custom-margin {
    margin-right: 0 !important;
  }
  .container {
    /* gap: 26.25px; */
    width: 100%;
    height: auto;
    margin-top: 100px;
  }
}

@media (max-width: 576px) {
  .feature-banner-1 {
    width: 100%;
    height: auto;
    background: #df4a31;
  }
  .feature-register {
    bottom: auto;
    right: auto;
  }
  .custom-margin {
    margin-right: 0 !important;
  }
  .feature-image {
    /* position: relative; */
    width: 350px;
    height: 150px;
    bottom: 10px;

    /* left: 10%; */
    /* top: 0px; */
  }

  .container {
    /* gap: 26.25px; */
    width: 100%;
    height: auto;
    margin-top: 100px;
  }
}
</style>
