<template>
  <div class="container">
    <!-- <HeaderCoursesComponent class="mb-4" /> -->
    <div class="courses-container">
      <LoadingComponent v-if="is_loading" />
      <template v-if="!is_loading">
        <course-card-component
          v-for="(item, index) in student_courses"
          :key="index"
          class="col-lg-4 col-12"
          :data="item"
          :subscribed="true"
        ></course-card-component>
      </template>
      <p v-if="student_courses.length == 0 && !is_loading" class="no-content">
        مفيش كورسات انت مشترك فيها لحد دلوقتي
      </p>
    </div>
    <PaginationBtns :next="hav_next" :prev="hav_prev" :link="'bills'" />
  </div>
</template>

<script>
import LoadingComponent from "@/components/Loading/LoadingComponent.vue";
// import HeaderCoursesComponent from "../HeaderCourses/HeaderCoursesComponent.vue";
import CourseCardComponent from "../CourseCard/CourseCardComponent.vue";
import axios from "axios";
import config from "@/services/config";
import PaginationBtns from "@/components/PaginationBtns/PaginationBtns.vue";
import { watchEffect } from "vue";

export default {
  name: "CoursesComponent",
  components: {
    // HeaderCoursesComponent,
    CourseCardComponent,
    LoadingComponent,
    PaginationBtns,
  },
  data() {
    return {
      student_courses: [],
      is_loading: true,
      hav_next: false,
      hav_prev: false,
    };
  },
  methods: {
    getCourses() {
      axios
        .get(`${config.baseURL}student/course-subscription-list`, {
          headers: {
            auth: this.$store.state.userToken,
          },
        })
        .then((res) => {
          console.log(res.data);
          this.student_courses = res.data.results;
        })
        .catch((err) => {
          console.log(err.message);
        })
        .finally(() => {
          this.is_loading = false;
        });
    },
  },
  created() {
    watchEffect(() => {
      this.getCourses();
    });
  },
};
</script>

<style scoped>
.courses-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}

.no-content {
  padding: 1rem 0.8rem;
  font-size: 1.3rem;
  font-weight: bold;
  text-align: center;
  width: 100%;
}

/* Dark Mode */
/*  Media queries */
@media screen and (max-width: 992px) {
  .courses-container {
    justify-content: center;
  }
}
</style>
